import React, { Component } from 'react';
import { ModelGrid, VariableBindings } from './modelGrid';
import { Hideable } from './hideable';

type PrefabRowProps = {
  // included
  rowStart: number,
  // not included
  rowEnd: number,
  gridIndex: number,
  grid: ModelGrid,
  updateBindings: (prefabName: string, inputBindings: VariableBindings, outputBindings: VariableBindings) => void,
  updatePrefabName: (oldName: string, newName: string) => void,
  showContextMenuFn: (x: number, y: number, contextMenuRow: number, contextMenuColumn: number) => void,
};

function getName(props: PrefabRowProps): string {
  return props.grid.rowGroupNames[props.rowStart];
}

function getInputBindings(props: PrefabRowProps): VariableBindings {
  const name = getName(props);
  let bindings = props.grid.componentModelInputBindings.get(name);
  if (bindings !== undefined) return bindings;
  return new Map<string, string>();
}

function getOutputBindings(props: PrefabRowProps): VariableBindings {
  const name = getName(props);
  let bindings = props.grid.componentModelOutputBindings.get(name);
  if (bindings !== undefined) return bindings;
  return new Map<string, string>();
}

type PrefabRowState = {
  name: string,
  inputBindings: VariableBindings,
  outputBindings: VariableBindings,
  showFormulas: boolean,
  editingName: boolean,
};

function BindingsEqual(a: VariableBindings, b: VariableBindings): boolean {
  if (a.size !== b.size) return false;
  a.forEach((value: string, key: string) => {
    if (!b.has(key) ||
        b.get(key) !== value) return false;
  });
  return true;
}

type PrefabBindingProps = {
  bindingName: string,
  value: string,
  onBlur: (bindingName: string, value: string) => void,
};

type PrefabBindingState = {
  value: string,
};

class PrefabBinding extends Component<PrefabBindingProps, PrefabBindingState> {
  handleChangeBound: (event: any) => void;
  handleBlurBound: (event: any) => void;
  constructor(props: PrefabBindingProps) {
    super(props);
    this.handleChangeBound = this.handleChange.bind(this);
    this.handleBlurBound = this.handleBlur.bind(this);
    this.state = {
      value: this.props.value,
    };
  }

  componentDidUpdate(prevProps: PrefabBindingProps, prevState: PrefabBindingState, snapshot: any) {
    if (prevProps.value === this.props.value) return;
    this.setState({
      value: this.props.value,
    });
  }

  handleChange(event: any) {
    this.setState({
      value: event.target.value,
    });
  }

  handleBlur(event: any) {
    const value: string = event.target.value;
    this.setState({
      value: value,
    });
    this.props.onBlur(this.props.bindingName, value);
  }

  render() {
    return (
        <div className="prefabBinding">{this.props.bindingName}
          <input
              type='text'
              placeholder={this.state.value}
              onChange={this.handleChangeBound}
              onBlur={this.handleBlurBound}
              value={this.state.value}
          />
        </div>
    );
  }
}

export class PrefabRow extends Component<PrefabRowProps, PrefabRowState> {
  handleBlurBound: (bindingName: string, value: string) => void;
  toggleShowFormulasBound: () => void;
  handleNameBlurBound: (event: any) => void;
  handleNameChangeBound: (event: any) => void;
  handleEditNameBound: (event: any) => void;
  showContextMenuBound: (event: any) => void;

  constructor(props: PrefabRowProps) {
    super(props);
    const name = getName(this.props);
    this.state = {
      name: name,
      inputBindings: getInputBindings(this.props),
      outputBindings: getOutputBindings(this.props),
      showFormulas: false,
      editingName: false,
    };
    this.handleBlurBound = this.handleBlur.bind(this);
    this.toggleShowFormulasBound = this.toggleShowFormulas.bind(this);
    this.handleNameBlurBound = this.handleNameBlur.bind(this);
    this.handleNameChangeBound = this.handleNameChange.bind(this);
    this.handleEditNameBound = this.handleEditName.bind(this);
    this.showContextMenuBound = this.showContextMenu.bind(this);
  }

  showContextMenu(e: any) {
    e.preventDefault();
    this.props.showContextMenuFn(e.clientX, e.clientY, this.props.rowStart, -1);
  }

  toggleShowFormulas() {
    this.setState({
      showFormulas: !this.state.showFormulas,
    });
  }

  componentDidUpdate(prevProps: PrefabRowProps, prevState: PrefabRowState, snapshot: any) {
    const name = getName(this.props);
    if (name !== getName(prevProps) ||
        (!this.state.editingName && (name !== this.state.name)) ||
        !BindingsEqual(getInputBindings(prevProps), getInputBindings(this.props)) ||
        !BindingsEqual(getOutputBindings(prevProps), getOutputBindings(this.props))) {
      this.setState({
        name: name,
        inputBindings: getInputBindings(this.props),
        outputBindings: getOutputBindings(this.props),
      });
    }
  }

  handleBlur(bindingName: string, value: string) {
    if (this.state.inputBindings.has(bindingName)) {
      this.state.inputBindings.set(bindingName, value);
    }
    if (this.state.outputBindings.has(bindingName)) {
      this.state.outputBindings.set(bindingName, value);
    }
    this.props.updateBindings(this.state.name, this.state.inputBindings, this.state.outputBindings);
    this.setState({
      inputBindings: this.state.inputBindings,
      outputBindings: this.state.outputBindings,
    });
  }

  handleNameBlur(event: any) {
    const name = event.target.value;
    const oldName = this.props.grid.rowGroupNames[this.props.rowStart];
    if (this.props.grid.componentModelInputBindings.has(name)) {
      this.setState({
        name: oldName,
        editingName: false,
      });
      return;
    }
    this.setState({
      name: name,
      editingName: false,
    });
    this.props.updatePrefabName(oldName, name);
  }

  handleNameChange(event: any) {
    this.setState({
      name: event.target.value,
    });
  }

  handleEditName(event: any) {
    this.setState({
      editingName: true,
    });
  }

  render() {
    let inputBindings: any[] = [];
    this.state.inputBindings.forEach((value: string, key: string) => {
      inputBindings.push(
        <PrefabBinding
          key={key}
          bindingName={key}
          value={value}
          onBlur={this.handleBlurBound}
        />
      );
    });
    let outputBindings: any[] = [];
    this.state.outputBindings.forEach((value: string, key: string) => {
      outputBindings.push(
        <PrefabBinding
          key={key}
          bindingName={key}
          value={value}
          onBlur={this.handleBlurBound}
        />
      );
    });
    let formulaRows: any[] = [];
    for (let i = this.props.rowStart; i < this.props.rowEnd; i++) {
      formulaRows.push(
        <div key={i}>
          <input
            value={this.props.grid.variableNames[i]}
            readOnly={true}
          />
          <input
            value={this.props.grid.columns[0][i].formula}
            readOnly={true}
          />
        </div>
      );
    }
    return (
      <div className="prefab"
        onContextMenu={this.showContextMenuBound}
      >
        <div className="prefabName">
          <Hideable hidden={this.state.editingName}>
            {this.state.name}
            <button onClick={this.handleEditNameBound}>Rename
            </button>
          </Hideable>
          <Hideable hidden={!this.state.editingName}>
            <input
              type='text'
              placeholder={this.state.name}
              onChange={this.handleNameChangeBound}
              onBlur={this.handleNameBlurBound}
              value={this.state.name}
            />
          </Hideable>
        </div>
        <div className="prefabInputColumns">
          <div className="prefabInputColumn">
            <div>Input Variables</div>
            {inputBindings}
          </div>
          <div className="prefabInputColumn">
            <div>Output Variables</div>
            {outputBindings}
          </div>
          <button onClick={this.toggleShowFormulasBound}>
            Show Component Details
          </button>
        </div>
        <Hideable hidden={!this.state.showFormulas}>
          {formulaRows}
        </Hideable>
      </div>
    );
  }
};
