import { ThymeActionTypes, SET_DURATION } from "../actionTypes";

export type ThymeStore = {
  duration: number,
}

const initialState: ThymeStore = {
  duration: 10,
}

export function thymeReducer(state = initialState, action: ThymeActionTypes): ThymeStore {
  switch (action.type) {
    case SET_DURATION: {
      const duration: number = action.payload;
      return {
        ...state,
        duration: duration,
      //allIds: [...state.allIds, id],
      //byIds: {
      //  ...state.byIds,
      //  [id]: {
      //    content,
      //    completed: false
      //  }
      //}
      };
    }
    default: {
      return state;
    }
  }
}
