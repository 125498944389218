/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getModel = /* GraphQL */ `
  query GetModel($id: ID!) {
    getModel(id: $id) {
      id
      ownerId
      name
      description
      columns
      initializerGrid
      updateGrid
      public
      publishedModelId
      selectedVariables
      inputVariables
      outputVariables
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listModels = /* GraphQL */ `
  query ListModels(
    $filter: ModelModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerId
        name
        description
        columns
        initializerGrid
        updateGrid
        public
        publishedModelId
        selectedVariables
        inputVariables
        outputVariables
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPublishedModel = /* GraphQL */ `
  query GetPublishedModel($id: ID!) {
    getPublishedModel(id: $id) {
      id
      ownerId
      name
      description
      columns
      initializerGrid
      updateGrid
      public
      publishedModelId
      selectedVariables
      inputVariables
      outputVariables
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPublishedModels = /* GraphQL */ `
  query ListPublishedModels(
    $filter: ModelPublishedModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublishedModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerId
        name
        description
        columns
        initializerGrid
        updateGrid
        public
        publishedModelId
        selectedVariables
        inputVariables
        outputVariables
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
