import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import React, { Component } from 'react';
import { calculate, initScope, ModelGrid } from './modelGrid';

type ChartProps = {
  initializerGrid: ModelGrid,
  incrementGrid: ModelGrid,
  columns: Array<string>,
  selectedVariables: Array<string>,
  duration: number,
}

type ChartState = {
  renderedInitializerVersion: number,
  renderedIncrementVersion: number,
}

function seriesName(columnName: string, variable: string) {
  return columnName + '-' + variable;
}

function averageSims(data: Array<number>) {
  let sum = 0.0;
  for (let d = 0; d < data.length; d++) {
    sum += data[d];
  }
  return sum / data.length;
}

function percentileSims(data: Array<number>, percentile: number) {
  data.sort();
  return data[Math.floor(percentile / 100 * data.length)];
}

export class Chart extends Component<ChartProps, ChartState> {
	private chart: any;
	private charts: Map<string, any> = new Map<string, any>();
  private valueAxis: any;

  componentDidMount() {
    this.chart = this.createChart("chartdiv");
  }

  constructor(props: ChartProps) {
    super(props);
    this.state = {
      renderedInitializerVersion: 0,
      renderedIncrementVersion: 0,
    };
  }

  updateSingle(chart: any, variableName: string) {
    let variableSet = new Set<string>();
    for (let variableName of this.props.initializerGrid.variableNames) {
      if (variableName === '') continue;
      variableSet.add(variableName);
    }
    for (let variableName of this.props.incrementGrid.variableNames) {
      if (variableName === '') continue;
      variableSet.add(variableName);
    }
    let data = this.simulate(variableSet);
    chart.data = data;
    let minValue = null;
    let maxValue = null;
    if (data === undefined) return;
    let variablesForRange = [
        variableName,
        variableName + '-errorTop',
        variableName + '-errorBottom'
    ];
    for (let t = 0; t < data.length; t++) {
      for (let k in variablesForRange) {
        let value = data[t][k];
        if (minValue === null || minValue > value) {
          minValue = value;
        }
        if (maxValue === null || maxValue < value) {
          maxValue = value;
        }
      }
    }
    this.valueAxis.min = minValue;
    this.valueAxis.max = maxValue;
  }

  addSeriesIfNeeded() {
    var valueAxis = this.valueAxis;
    while (this.chart.series.length < this.props.columns.length) {
      let series = this.chart.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = "date";
      series.dataFields.valueY = 'filled in later update'

      series.tooltipText = 'filled in later update: {valueY.value}';
      this.chart.scrollbarX.series.push(series);

      let errorBulletTop = series.bullets.create(am4charts.ErrorBullet);
      errorBulletTop.isDynamic = true;
      errorBulletTop.strokeWidth = 2;

			errorBulletTop.adapter.add("pixelHeight", function (pixelHeight: number, target: any) {
				var dataItem = target.dataItem;

			  if(dataItem){
          let axisName = dataItem.component.dataFields.valueY;
			  	let errorTopValue = dataItem.dataContext[axisName + '-errorTop'];
			  	let errorTopY = valueAxis.valueToPoint(errorTopValue).y;

			  	let errorBottomValue = dataItem.valueY;
			    let errorBottomY = valueAxis.valueToPoint(errorBottomValue).y;

			  	return Math.abs(errorTopY - errorBottomY);
			  }
        return 0;
			});
			errorBulletTop.adapter.add("dy", function (dy: number, target: any) {
				var dataItem = target.dataItem;

			  if(dataItem){
          let axisName = dataItem.component.dataFields.valueY;
			  	let errorTopValue = dataItem.dataContext[axisName + '-errorTop'];
			  	let errorTopY = valueAxis.valueToPoint(errorTopValue).y;

			  	let errorBottomValue = dataItem.valueY;
			    let errorBottomY = valueAxis.valueToPoint(errorBottomValue).y;

			  	return -Math.abs(errorTopY - errorBottomY) / 2;
			  }
        return 0;
      });
      let errorBulletBottom = series.bullets.create(am4charts.ErrorBullet);
      errorBulletBottom.isDynamic = true;
      errorBulletBottom.strokeWidth = 2;

			errorBulletBottom.adapter.add("pixelHeight", function (pixelHeight: number, target: any) {
				var dataItem = target.dataItem;

			  if(dataItem){
          let axisName = dataItem.component.dataFields.valueY;
			  	let errorBottomValue = dataItem.dataContext[axisName + '-errorBottom'];
			  	let errorBottomY = valueAxis.valueToPoint(errorBottomValue).y;

			  	let errorTopValue = dataItem.valueY;
			    let errorTopY = valueAxis.valueToPoint(errorTopValue).y;

			  	return Math.abs(errorTopY - errorBottomY);
			  }
        return 0;
			});
			errorBulletBottom.adapter.add("dy", function (dy: number, target: any) {
				var dataItem = target.dataItem;

			  if(dataItem){
          let axisName = dataItem.component.dataFields.valueY;
			  	let errorBottomValue = dataItem.dataContext[axisName + '-errorBottom'];
			  	let errorBottomY = valueAxis.valueToPoint(errorBottomValue).y;

			  	let errorTopValue = dataItem.valueY;
			    let errorTopY = valueAxis.valueToPoint(errorTopValue).y;

			  	return Math.abs(errorTopY - errorBottomY) / 2;
			  }
        return 0;
			});
    }
  }

  setSeriesNames(variableName: string) {
    for (let i = 0; i < this.props.columns.length; i++) {
      let series = this.chart.series.getIndex(i);
      series.dataFields.valueY = this.props.columns[i] + '-' + variableName;
      series.tooltipText = this.props.columns[i] + ': {valueY.value}';
    }
  }

  createChart(name: string) {
    let chart = am4core.create(
      name,
      am4charts.XYChart
    );
    chart.data = [];

    chart.cursor = new am4charts.XYCursor();
    let scrollbarX = new am4charts.XYChartScrollbar();
    chart.scrollbarX = scrollbarX;


    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;

    this.valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    chart.legend = new am4charts.Legend();

  //let title = chart.titles.create();
  //title.text = variableName;
  //title.fontSize = 25;
  //title.marginBottom = 30;
    return chart;
  }

  simulate(variableSet: Set<string>) {
		let data: any = [];
		let sims: any = [];
    for (let t = 0; t < this.props.duration; t++) {
      data.push({
        date: new Date(2021 + t, 1, 1),
      })
      sims.push({});
    }
    for (let i = 0; i < this.props.initializerGrid.columns.length; i++) {
      let columnName = this.props.columns[i];
      for (let t = 0; t < this.props.duration; t++) {
        for (let variable of Array.from(variableSet.values())) {
          sims[t][seriesName(columnName, variable)] = [];
        }
      }
    }

    const numSimulations = 100;
    for (let s = 0; s < numSimulations; s++) {
      let sAndP500Returns = [];
      {
        const historicalReturns = [15.87, 16.26, 28.88, -6.24, 19.42, 9.54, -0.73, 11.39, 29.60, 13.41, 0.00, 12.78, 23.45, -38.49, 3.53, 13.62, 3.00, 8.99, 26.38, -23.37, -13.04, -10.14, 19.53, 26.67, 31.01, 20.26, 34.11, -1.54, 7.06, 4.46, 26.31, -6.56];
        for (let t = 0; t < this.props.duration; t++) {
          let marketPercentChange = historicalReturns[Math.floor(Math.random() * historicalReturns.length)] / 100;
          sAndP500Returns.push(marketPercentChange);
        }
      }
      for (let i = 0; i < this.props.initializerGrid.columns.length; i++) {
        const columnName = this.props.columns[i];
        const rowGroupNames = this.props.initializerGrid.rowGroupNames.concat(this.props.initializerGrid.rowGroupNames);
        let scope = initScope(rowGroupNames);
        scope = calculate(this.props.initializerGrid, i, scope);
        if (scope.error) return;
        for (let t = 0; t < this.props.duration; t++) {
          scope.namespaces.get('').S_P_500_RETURN = sAndP500Returns[t];
          scope = calculate(this.props.incrementGrid, i, scope);
          if (scope.error) return;
          for (let variable of Array.from(variableSet.values())) {
            sims[t][seriesName(columnName, variable)].push(scope.namespaces.get('')[variable]);
          };
        }
      }
    }

    for (let i = 0; i < this.props.initializerGrid.columns.length; i++) {
      const columnName = this.props.columns[i];
      for (let t = 0; t < this.props.duration; t++) {
        for (let variable of Array.from(variableSet.values())) {
          let key = seriesName(columnName, variable);
          let simulatedValues = sims[t][key]
          data[t][key] = averageSims(simulatedValues);
          data[t][key + '-errorBottom'] = percentileSims(simulatedValues, 25);
          data[t][key + '-errorTop'] = percentileSims(simulatedValues, 75)
        }
      }
    }

    return data;
  }

  componentDidUpdate(prevProps: ChartProps, prevState: ChartState, snapshot: any) {
    if (this.state.renderedIncrementVersion === this.props.incrementGrid.version &&
        this.state.renderedInitializerVersion === this.props.initializerGrid.version &&
        this.props.columns === prevProps.columns &&
        this.props.selectedVariables === prevProps.selectedVariables) {
      return;
    }

    this.updateSingle(this.chart, this.props.selectedVariables[0]);
    this.addSeriesIfNeeded();
    this.setSeriesNames(this.props.selectedVariables[0]);
    this.setState({
      renderedInitializerVersion: this.props.initializerGrid.version,
      renderedIncrementVersion: this.props.incrementGrid.version,
    });
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  render() {
    console.log('CHART COMPONENT RENDER');
    return (
      <div>
      <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
      </div>
    )
  }
}
