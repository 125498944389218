import { Step } from 'react-joyride';

const studentLoanJoyride: Array<Step> = [
  {
    target: '.modelTitle',
    content: "Welcome to Simulatory. Let's look at how this 'Student Loan' model can help you decide when to repay your student loans.",
    disableOverlay: true,
    disableBeacon: true,
  },
  {
    target: '.Cell0-0-0',
    content: "First, enter your specific loan parameters, like annual interest rate (APR) and amount owed, in the 'Keep Paying' decision column. This lets us calculate the cost of the loan over time.",
    disableOverlay: true,
    placement: 'right',
  },
  {
    target: '.Cell0-5-0',
    content: "We assume here that you have enough saved up to pay off the debt, but will keep that money in an S&P 500 fund if you don't pay off the loan.",
    disableOverlay: true,
    placement: 'right',
  },
  {
    target: '.Cell0-2-1',
    content: "In Simulatory, each column represents a different decision. So, the first column calculates keeping the loan as-is, while the second sets up repayment. In this case, we take cash out of Investments to instead reduce the AmountOwed. You should start with the same net worth in either case.",
    disableOverlay: true,
    placement: 'right',
  },
  {
    target: '.Cell1-0-2',
    content: "While the top section in Simulatory does a one-time setup of initial values, this second section gets recalculated each year. So, we can see how our investments grow relative to interest payments.",
    disableOverlay: true,
    placement: 'right',
  },
  {
    target: '.Cell1-3-1',
    content: "We model returns by picking the S&P500 annual return over one of the previous 30 years, for each year simulated.",
    disableOverlay: true,
    placement: 'right',
  },
  {
    target: '.Checkbox1-5',
    content: "You can plot any variable over time. In this case, 'NetWorth' is probably the most interesting to watch.",
    disableOverlay: true,
  },
  {
    target: '.chart',
    content: "Finally, take a look at the chart to see how your net worth might grow in each scenario. In general, you should see that if market returns (averaging 9%) are higher than the interest, you're better off paying slowly -- but it's not guaranteed, as the market is unpredictable. The model runs many simulations, so you can see the error bars representing the middle 50% of a distribution of outcomes.",
    disableOverlay: true,
  },
  {
    target: '.Cell1-1-1',
    content: "Feel free to edit any of these fields and see what happens. You can add more rows or columns by right clicking on a cell if you want to tailor the model to your situation. Enjoy!",
    disableOverlay: true,
  },
];

const mineralsJoyride: Array<Step> = [
  {
    target: '.modelTitle',
    content: "Welcome to Simulatory. Let's look at how this 'Selling Mineral Rights' model can help you decide whether to sell a valuable commodity.",
    disableOverlay: true,
    disableBeacon: true,
  },
  {
    target: '.Cell0-0-0',
    content: "First, enter your annual income and sale offer.",
    disableOverlay: true,
    placement: 'right',
  },
  {
    target: '.Cell0-2-1',
    content: "In Simulatory, each column represents a different decision. So, the first column calculates keeping the mineral rights, while the second calculates selling it and putting the returns in Investments.",
    disableOverlay: true,
    placement: 'right',
  },
  {
    target: '.Cell1-0-1',
    content: "While the top section in Simulatory does a one-time setup of initial values, this second section gets recalculated each year. So, we can see how our investments grow and royalty income flows in.",
    disableOverlay: true,
    placement: 'right',
  },
  {
    target: '.Cell1-0-1',
    content: "We model returns by picking the S&P500 annual return over one of the previous 30 years, for each year simulated. Note that for now we ignore inflation and just assume that the value of the property stays constant.",
    disableOverlay: true,
    placement: 'right',
  },
  {
    target: '.Checkbox1-2',
    content: "You can plot any variable over time. In this case, 'NetWorth' is probably the most interesting to watch.",
    disableOverlay: true,
  },
  {
    target: '.chart',
    content: "Finally, take a look at the chart to see how your net worth might grow in each scenario. In general, you should see that if the purchase price is more than 11x the annual income, you're better off selling -- but it's not guaranteed, as the market is unpredictable. The model runs many simulations, so you can see the error bars representing the middle 50% of a distribution of outcomes.",
    disableOverlay: true,
  },
  {
    target: '.Cell1-1-1',
    content: "Feel free to edit any of these fields and see what happens. You can add more rows or columns by right clicking on a cell if you want to tailor the model to your situation. Enjoy!",
    disableOverlay: true,
  },
];

const vacationHouseJoyride: Array<Step> = [
  {
    target: '.modelTitle',
    content: "Welcome to Simulatory. Let's look at how this 'Vacation House' model can help you decide how much vacation house you could afford.",
    disableOverlay: true,
    disableBeacon: true,
  },
  {
    target: '.Cell0-0-0',
    content: "First, enter your basic financial numbers. This lets us calculate future net worth with and without the extra expense.",
    disableOverlay: true,
    placement: 'right',
  },
  {
    target: '.Cell0-2-1',
    content: "In Simulatory, each column represents a different decision. So, the first column calculates keeping your financial status as-is. In the second column, we take cash out of Investments to make a down payment against VacationHouseValue. You should start with the same net worth in either case.",
    disableOverlay: true,
    placement: 'right',
  },
  {
    target: '.UpdatesEachTimeHeader',
    content: "While the top section in Simulatory does a one-time setup of initial values, this second section gets recalculated each year. So, we can see how our investments grow relative to interest payments.",
    disableOverlay: true,
    placement: 'right',
  },
  {
    target: '.Cell1-3-1',
    content: "We model returns by picking the S&P500 annual return over one of the previous 30 years, for each year simulated.",
    disableOverlay: true,
    placement: 'right',
  },
  {
    target: '.Checkbox1-4',
    content: "You can plot any variable over time. In this case, 'NetWorth' is probably the most interesting to watch.",
    disableOverlay: true,
  },
  {
    target: '.chart',
    content: "Finally, take a look at the chart to see how your net worth might grow in each scenario. In general, a higher vacation house expenditure will lower eventual net worth, but it may be worth it for the enjoyment. The model runs many simulations, so you can see the error bars representing the middle 50% of a distribution of outcomes.",
    disableOverlay: true,
  },
  {
    target: '.Cell1-1-1',
    content: "Feel free to edit any of these fields and see what happens. You can add more rows or columns by right clicking on a cell if you want to tailor the model to your situation. Enjoy!",
    disableOverlay: true,
  },
];

export function getJoyrideSteps(name: string): Array<Step> | null {
  switch (name) {
    case "studentLoan":
      return studentLoanJoyride;
    case "mineralRights":
      return mineralsJoyride;
    case "vacationHouse":
      return vacationHouseJoyride;
    default:
      return null;
  }
}

export function getJoyrideFromHash(): Array<Step> | null {
  let hash = window.location.hash.substr(1);
  let hashParts = hash.split('&');
  for (let i = 0; i < hashParts.length; i++) {
    let keyValue = hashParts[i].split('=');
    if (keyValue.length === 2 &&
        keyValue[0] === 'tour') {
      return getJoyrideSteps(keyValue[1]);
    }
  }
  return null;
}

