import React, { Component } from 'react';
import { Hideable } from './hideable';
import { updateModel, updatePublishedModel } from './graphql/mutations';
import API from '@aws-amplify/api';
import { Model } from './models/index.d';

type PrefabModalProps = {
  dismissModal: () => void;
  model: Model;
  userId: string;
}

type InputOutput = {
  input: boolean,
  output: boolean,
}

type PrefabModalState = {
  ioSettings: Map<string, InputOutput>,
  publicPrefab: boolean,
  description: string,
  title: string,
}

export class PrefabModal extends Component<PrefabModalProps, PrefabModalState> {
  handleSubmitBound: (event: React.FormEvent<HTMLFormElement>) => void;
  handleInputChangeBound: (event: React.ChangeEvent<HTMLInputElement>) => void;
  constructor(props: PrefabModalProps) {
    super(props);
    this.handleSubmitBound = this.handleSubmit.bind(this);
    this.handleInputChangeBound = this.handleInputChange.bind(this);
    this.state = {
      ioSettings: new Map(),
      publicPrefab: false,
      description: '',
      title: '',
    }
  }

  componentDidMount() {
    this.setupModal();
  }

  setupModal() {
    if (this.props.model !== null &&
        this.props.model.initializerGrid !== null &&
        this.props.model.updateGrid !== null) {
      console.log('Update modal');
      let ioSettings = new Map();
      let variableLists: Array<Array<string>> = [
        JSON.parse(this.props.model.initializerGrid!).variableNames,
        JSON.parse(this.props.model.updateGrid!).variableNames,
      ];
      for (let i = 0; i < variableLists.length; i++) {
        let variableList = variableLists[i];
        for (let j = 0; j < variableList.length; j++) {
          let name = variableList[j];
          if (name === '') continue;
          ioSettings.set(name, {input: false, output: false});
        }
      }
      this.setState({
        ioSettings: ioSettings,
        description: '',
        title: this.props.model.name || '',
      });
    }
  }
  componentDidUpdate(prevProps: PrefabModalProps, prevState: PrefabModalState, snapshot: any) {
    if (prevProps.model !== this.props.model) {
      this.setupModal();
    }
  }

  async publishModel() {
    if (this.props.model === null) return;
    let inputVariables: Array<string> = new Array<string>();
    let outputVariables: Array<string> = new Array<string>();
    this.state.ioSettings.forEach((value: InputOutput, key: string) => {
      if (value.input) {
        inputVariables.push(key);
      }
      if (value.output) {
        outputVariables.push(key);
      }
    });
    let updatePrivatePromise = API.graphql({
        query: updateModel,
        variables: { input: {
          id: this.props.model.id,
          description: this.state.description,
          inputVariables: inputVariables,
          outputVariables: outputVariables
        }
      }
    }) as Promise<any>;
    if (this.state.publicPrefab &&
        this.props.model.public) {
      let updateShared = API.graphql({
          query: updatePublishedModel,
          variables: { input: {
            id: this.props.model.publishedModelId,
            name: this.props.model.name,
            description: this.state.description,
            columns: this.props.model.columns,
            initializerGrid: this.props.model.initializerGrid,
            updateGrid: this.props.model.updateGrid,
            selectedVariables: this.props.model.selectedVariables,
            inputVariables: inputVariables,
            outputVariables: outputVariables
          }
      }}) as any;
      if (updateShared.data.updateModel === null) {
        console.log('Public Prefab FAILED: ', updateShared);
      } else {
        console.log('Public Prefab created: ', updateShared);
      }
    }
    let updated = await updatePrivatePromise;
    if (updated.data.updateModel === null) return;
    console.log('Prefab created: ', updated);
    this.props.dismissModal();
  }

  handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    this.publishModel();
  }

  handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.target;
    const value = target.value;
    console.log('Input change: ', target.name);
    switch (target.name) {
      case 'description':
        this.setState({description: value});
        break;
      case 'title':
        this.setState({title: value});
        break;
      case 'publicPrefab':
        this.setState({publicPrefab: !this.state.publicPrefab});
        break;
      default:
        this.state.ioSettings.forEach((value: InputOutput, key: string) => {
          if ("INPUT-" + key === target.name) {
            this.state.ioSettings.set(key, {input: !value.input, output: value.output});
            this.setState({ioSettings: this.state.ioSettings});
          } else if ("OUTPUT-" + key === target.name) {
            this.state.ioSettings.set(key, {input: value.input, output: !value.output});
            this.setState({ioSettings: this.state.ioSettings});
          }
        });
        return;
    }
  }

  render() {
    let rows: any[] = [];
    if (this.state.ioSettings.size === 0) return null;
    this.state.ioSettings.forEach((value: InputOutput, key: string) => {
      let name = key;
      let ioSetting = value;
      rows.push(
        <tr key={name}>
          <td>{name}</td>
          <td><input
            type="checkbox"
            name={"INPUT-" + name}
            checked={ioSetting.input}
            onChange={this.handleInputChangeBound}
            />
          </td>
          <td><input
            type="checkbox"
            name={"OUTPUT-" + name}
            checked={ioSetting.output}
            onChange={this.handleInputChangeBound}
            />
          </td>
        </tr>
      );
    });


    return (<div>
      <div className="overlay" onClick={this.props.dismissModal}>
      </div>
      <div className="prefabModal">
        <Hideable hidden={this.props.model === null}>
          <div className="prefabModalTitle">
            Create a Prefab
          </div>
          <div className="prefabModalTitle">
            Turn your model into a prefab component that can be used in other models.
          </div>
          <form
            onSubmit={this.handleSubmitBound}
            >
            <div className="prefabModalTextInput">
              Component Title:
              <input
                name="title"
                value={this.state.title}
                onChange={this.handleInputChangeBound} />
            </div>
            <div className="prefabModalTextInput">
              Model Description:
              <input
                name="description"
                value={this.state.description}
                onChange={this.handleInputChangeBound} />
            </div>
            Public:
            <input
              type="checkbox"
              name="publicPrefab"
              onChange={this.handleInputChangeBound}
              checked={this.state.publicPrefab}
            />
            <div>Select which variables are shown externally to others who use
                   this prefab.
            </div>
            <table>
              <tr>
                <td>Variable Name</td>
                <td>Take as Input</td>
                <td>Return as Output</td>
              </tr>
              {rows}
            </table>
            <input className="bodyButton" type="submit"></input>
          </form>
        </Hideable>
      </div>
    </div>);
  }
};
