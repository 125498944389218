import { parse } from 'mathjs';
import { CellContent, defaultCellContent } from './cellType';

export type ModelColumn = Array<CellContent>;
export type VariableBindings = Map<string, string>;
export type ModelGrid = {
  variableNames: Array<string>,
  columns: Array<ModelColumn>,
  // One entry per row.
  rowGroupNames: Array<string>,
  version: number,
  // Maps from rowGroupName to external variable -> internal variable
  componentModelInputBindings: Map<string, VariableBindings>
  // Maps from rowGroupName to internal variable -> external variable
  componentModelOutputBindings: Map<string, VariableBindings>,
}
export type VariableNames = Array<string>;

function updateDefaultsInRow(row: number, grid: ModelGrid) {
  let lastCell = grid.columns[0][row];
  for (let column = 1; column < grid.columns.length; column++) {
    let cell = grid.columns[column][row];
    if (cell.isDefault) {
      cell.formula = lastCell.formula;
      cell.compiledNode = lastCell.compiledNode;
      cell.hasExecutionError = lastCell.hasExecutionError;
    } else {
      lastCell = cell;
    }
  }
}

function updateDefaultsInColumn(updateFromColumn: number, grid: ModelGrid) {
  updateFromColumn = Math.max(1, updateFromColumn - 1);
  for (let column = updateFromColumn; column < grid.columns.length; column++) {
    for (let row = 0; row < grid.columns[column].length; row++) {
      let cell = grid.columns[column][row];
      if (cell.isDefault) {
        let lastCell = grid.columns[column - 1][row];
        cell.compiledNode = lastCell.compiledNode;
        cell.formula = lastCell.formula;
        cell.hasExecutionError = lastCell.hasExecutionError;
      }
    }
  }
}

export function resizeIfCellOutOfBounds(grid: ModelGrid, row: number, column: number) {
  let updatedColumn = false;
  while (column >= grid.columns.length) {
    updatedColumn = true;
    grid.columns.push([]);
    grid.version += 1;
    console.log('version change resize');
  }
  const maxRows = Math.max(row + 1, Math.max(...grid.columns.map(c => c.length)));
  for (let column = 0; column < grid.columns.length; column++) {
    while (maxRows > grid.columns[column].length) {
    //if (column > 0) {
    //  grid.columns[column].push(
    //      grid.columns[column - 1][grid.columns[column].length]);
    //} else {
        grid.columns[column].push(defaultCellContent());
        grid.version += 1;
        console.log('version change resize2');
    //}
    }
  }
  while (maxRows > grid.variableNames.length) {
    grid.variableNames.push('');
    grid.rowGroupNames.push('');
    grid.version += 1;
    console.log('version change resize3');
  }
  if (updatedColumn) {
    updateDefaultsInColumn(column, grid);
  }
  console.log('RESIZED: ', grid.columns.length + ' decision columns and ' + grid.variableNames.length + ' rows');
}

// JSON can't directly deserialize working maps. Instead, we use these functions to make
// it serialized into a more primitive type.
function replacer(key: string, value: any): any {
  if (!(value instanceof Map)) return value;
  return {
      dataType: 'Map',
      value: Array.from(value.entries()), // or with spread: value: [...value]
  };
}

export function serializeGrid(grid: ModelGrid) {
  return JSON.stringify(grid, replacer);
}

function reviver(key: string, value: any): any {
  if (typeof value !== 'object') return value;
  if (value === null) return value;
  if (value.dataType !== 'Map') return value;
  return new Map(value.value);
}

export function initialize(serializedGrid: string): ModelGrid {
  let grid: ModelGrid = JSON.parse(serializedGrid, reviver);
  console.log('intializing grid: ', grid);
  for (let column = 0; column < grid.columns.length; column++) {
    for (let row = 0; row < grid.columns[column].length; row++) {
      try {
        grid.columns[column][row].compiledNode = parse(grid.columns[column][row].formula).compile();
      } catch (e) {
        grid.columns[column][row].hasExecutionError = true;
      }
    }
  }
  if (grid.rowGroupNames === undefined || grid.rowGroupNames.length !== grid.columns[0].length) {
    grid.rowGroupNames = [];
    if (grid.columns.length > 0) {
      for (let row = 0; row < grid.columns[0].length; row++) {
        grid.rowGroupNames.push('');
      }
    }
  }
  if (grid.componentModelInputBindings === null ||
      !(grid.componentModelInputBindings instanceof Map)) {
    console.log('adding componentModelInputBindings');
    grid.componentModelInputBindings = new Map<string, VariableBindings>();
  }
  if (grid.componentModelOutputBindings === null ||
      !(grid.componentModelOutputBindings instanceof Map)) {
    grid.componentModelOutputBindings = new Map<string, VariableBindings>();
  }
  grid.version = 0;
  console.log('version change init: ', grid);
  return grid;
}

export function appendTo(name: string, gridToExtend: ModelGrid, grid: ModelGrid,
                         inputVariables: Array<string>, outputVariables: Array<string>): ModelGrid {
  if (grid.columns.length === 0 || gridToExtend.columns.length === 0) return gridToExtend;
  for (let row = 0; row < grid.columns[0].length; row++) {
    gridToExtend.columns[0].push(grid.columns[0][row]);
    gridToExtend.variableNames.push(grid.variableNames[row]);
    gridToExtend.rowGroupNames.push(name);
  }
  let inputBindings: VariableBindings = new Map<string, string>();
  let outputBindings: VariableBindings = new Map<string, string>();
  inputVariables.forEach((variable: string) => {
    inputBindings.set(variable, variable);
  });
  outputVariables.forEach((variable: string) => {
    outputBindings.set(variable, variable);
  });
  console.log(typeof gridToExtend.componentModelInputBindings);
  gridToExtend.componentModelInputBindings.set(name, inputBindings);
  gridToExtend.componentModelOutputBindings.set(name, outputBindings);
  resizeIfCellOutOfBounds(gridToExtend, grid.columns[0].length - 1, 0);
  updateDefaultsInColumn(0, gridToExtend);
  grid.version += 1;
  console.log('version change append');
  return gridToExtend;
}

export function nextNonPrefabRow(grid: ModelGrid, row: number) {
  const rowGroupNames = grid.rowGroupNames;
  const prefabName = rowGroupNames[row];
  let next = row + 1
  for (; next < rowGroupNames.length; next++)  {
    const nextName = rowGroupNames[row];
    if (nextName === '' || nextName !== prefabName) {
      return next;
    }
  }
  return next;
}

export function insertRow(grid: ModelGrid, row:  number) {
	console.log('Insert Row', row);
  for (let column = 0; column < grid.columns.length; column++) {
		grid.columns[column].splice(row, 0, defaultCellContent());
	}
	grid.variableNames.splice(row, 0, 'inserted');
	grid.rowGroupNames.splice(row, 0, '');
  grid.version += 1;
  console.log('version change insert');
}

export function deleteRow(grid: ModelGrid, row:  number) {
  let prefabName = grid.rowGroupNames[row];
  let rowsToDelete = 1;
  for (let i = row + 1; i < grid.rowGroupNames.length; i++) {
    if (grid.rowGroupNames[i] === '' ||
        grid.rowGroupNames[i] !== prefabName) break;
    rowsToDelete++;
  }
  for (let column = 0; column < grid.columns.length; column++) {
		grid.columns[column].splice(row, rowsToDelete);
	}
	grid.variableNames.splice(row, rowsToDelete);
	grid.rowGroupNames.splice(row, rowsToDelete);
  grid.version += 1;
  console.log('version change delete');
}

export function insertColumn(grid: ModelGrid, column:  number) {
  console.log('Insert column', column);
	grid.columns.splice(column, 0, []);
  const maxRows = Math.max(...grid.columns.map(c => c.length));
	while (maxRows > grid.columns[column].length) {
			grid.columns[column].push(defaultCellContent());
	}
	updateDefaultsInColumn(column, grid);
  grid.version += 1;
  console.log('version change insert');
}

export function deleteColumn(grid: ModelGrid, column:  number) {
	grid.columns.splice(column, 1);
	updateDefaultsInColumn(column, grid);
  grid.version += 1;
  console.log('version change delete');
}

export function updateModelVariable(grid: ModelGrid, row: number, variableName: string) {
  resizeIfCellOutOfBounds(grid, row, 0);
  grid.variableNames[row] = variableName;
  grid.version += 1;
  console.log('version change update');
}

export function updateModel(grid: ModelGrid, row: number, column: number, cellContent: CellContent) {
  resizeIfCellOutOfBounds(grid, row, column);
  grid.columns[column][row] = cellContent;
  updateDefaultsInRow(row, grid);
  grid.version += 1;
  console.log('version change update2');
}

export type Scope = {
  error: boolean,
  namespaces: Map<string, any>,
}
export function initScope(rowGroupNames: Array<string>): Scope {
  let scope: Scope = {
    error: false,
    namespaces: new Map<string, any>(),
  }
  scope.namespaces.set('', {});
  rowGroupNames.forEach((name: string) => {
    scope.namespaces.set(name, {});
  });
  return scope;
}

export function calculate(grid: ModelGrid, column: number, scope: Scope): any {
  if (grid.columns.length <= column) return scope;
  scope.error = false;
  let lastRowGroupName = '';
  let localScope = scope.namespaces.get('');
  for (let i = 0; i < grid.columns[column].length; i++) {
    const variableName = grid.variableNames[i];
    const rowGroupName = grid.rowGroupNames[i];
    if (rowGroupName !== lastRowGroupName) {
      let lastScope = localScope;
      localScope = scope.namespaces.get(rowGroupName);
      // TODO: transition scope in and out
      if (rowGroupName !== '') {
        let componentModelInputBindings = grid.componentModelInputBindings.get(rowGroupName);
        if (componentModelInputBindings !== undefined) {
          componentModelInputBindings.forEach((value: string, key: string) => {
            localScope[value] = lastScope[key];
          });
        }
      }
      if (lastRowGroupName !== '') {
        let componentModelOutputBindings = grid.componentModelOutputBindings.get(lastRowGroupName);
        if (componentModelOutputBindings !== undefined) {
          componentModelOutputBindings.forEach((value: string, key: string) => {
            localScope[value] = lastScope[key];
          });
        }
      }
    }
    lastRowGroupName = rowGroupName;
    try {
      const evaluation: any= grid.columns[column][i].compiledNode.evaluate(localScope);
      localScope[variableName] = evaluation;
      grid.columns[column][i].errors = [];
      grid.columns[column][i].hasExecutionError = false;
    } catch (e) {
      console.log('Calculate error', e);
      grid.columns[column][i].hasExecutionError = true;
      grid.columns[column][i].errors = [e.message];
      scope.error = true;
      // TODO: flag errors. This doesn't trigger props to update.
    }
  }
  return scope;
}

export function newModelGrid(): ModelGrid {
  let grid = {
    variableNames: new Array<string>(),
    columns: new Array<ModelColumn>(),
    rowGroupNames: new Array<string>(),
    version: 0,
    componentModelInputBindings: new Map<string, VariableBindings>(),
    componentModelOutputBindings: new Map<string, VariableBindings>(),
  };
  resizeIfCellOutOfBounds(grid, 1, 0);
  return grid;
}
