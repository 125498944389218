import React, { Component } from 'react';
import API, { graphqlOperation } from '@aws-amplify/api';
import { getPrivateOrPublicModel, copyModel } from './queries';
import { createPublishedModel, } from './graphql/mutations';
import { updateModel, deletePublishedModel, deleteModel } from './graphql/mutations';
import { Auth } from 'aws-amplify'
import { Hideable } from './hideable';
import { PrefabModal } from './prefabModal';
import { Model } from './models/index.d';

type PageHeaderProps = {
  showSelectorFn: () => void,
  modelId: string,
  userId: string,
  showLoginFn: () => void,
  selectModel: (modelId: string) => void,
  showLoginCopyFlow: (modelId: string) => void,
}

type PageHeaderState = {
  title: string,
  shared: boolean,
  owned: boolean,
  showDeleteModel: boolean,
  redirectToHome: boolean,
  showPrefabModal: boolean,
  model: Model | null,
}

function signOut(): void {
  Auth.signOut();
  window.location.reload();
}

export class PageHeader extends Component<PageHeaderProps, PageHeaderState> {
  pickModelBound: () => void;
	copyModelBound: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  logoutBound: () => void;
  createPrefabBound: () => void;
  dismissPrefabBound: () => void;
	toggleShareBound: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	setTitleBound: (e: React.ChangeEvent<HTMLInputElement>) => void;
	updateTitleBound: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showDeleteModelBound: () => void;
  dismissDeleteModelBound: () => void;
  deleteModelBound: () => void;
  simulatoryClickBound: () => void;

  constructor(props: PageHeaderProps) {
    super(props);
    this.pickModelBound = this.pickModel.bind(this);
    this.logoutBound = this.logout.bind(this);
    this.setTitleBound = this.setTitle.bind(this);
    this.updateTitleBound = this.updateTitle.bind(this);
    this.toggleShareBound = this.toggleShare.bind(this);
    this.copyModelBound = this.handleCopyModel.bind(this);
    this.showDeleteModelBound = this.showDeleteModel.bind(this);
    this.dismissDeleteModelBound = this.dismissDeleteModel.bind(this);
    this.deleteModelBound = this.deleteModel.bind(this);
    this.simulatoryClickBound = this.simulatoryClick.bind(this);
    this.createPrefabBound = this.createPrefab.bind(this);
    this.dismissPrefabBound = this.dismissPrefab.bind(this);
    this.state = {
      title: 'None',
      shared: false,
      owned: true,
      showDeleteModel: false,
      redirectToHome: false,
      showPrefabModal: false,
      model: null,
    }
  }

  componentDidMount() {
    this.getModel();
  }

  simulatoryClick() {
    this.setState({
      redirectToHome: true,
    });
  }

	handleCopyModel(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.copyModelAsync();
  }

  createPrefab() {
    this.setState({
      showPrefabModal: true,
    });
  }

  dismissPrefab() {
    this.setState({
      showPrefabModal: false,
    });
  }

	toggleShare(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    try {
      this.updateSharing(!this.state.shared);
    } catch (e) {
      console.log(e);
    }
  }

  componentDidUpdate(prevProps: PageHeaderProps, prevState: PageHeaderState, snapshot: any) {
    if (prevProps.modelId === this.props.modelId) return;
    console.log('Updated model id = ', this.props.modelId);
    if (this.props.modelId.length === 0) return;
    this.getModel();
  }

  async updateSharing(shared: boolean) {
    console.log('model sharing ->', shared);
    const model = await getPrivateOrPublicModel(this.props.modelId);
    let publishedModelId = null;
    if (shared) {
      let publish = await API.graphql({
          query: createPublishedModel,
          variables: { input: {
              ownerId: model.ownerId,
              name: model.name,
              columns: model.columns,
              initializerGrid: model.initializerGrid,
              updateGrid: model.updateGrid,
              public: true,
              selectedVariables: model.selectedVariables
          }}}) as any;
      if (publish.data.createPublishedModel === null) return;
      publishedModelId = publish.data.createPublishedModel.id;
    } else if (model.publishedModelId !== null) {
      let deleteResponse = await API.graphql({
          query: deletePublishedModel,
          variables: { input: {
            id: model.publishedModelId,
          }}});
      // TODO: handle errors
    }
    await API.graphql({
        query: updateModel,
        variables: {
            input: {id: this.props.modelId,
            publishedModelId: publishedModelId,
            public: shared}
        }});
    this.getModel();
  }

  async getModel() {
    const model = await getPrivateOrPublicModel(this.props.modelId);
    if (model === null) return;
    const shared: boolean = model.public === undefined ? false : model.public;
  //console.log('Model shared = ', shared);
  //if (model.ownerId !== this.props.userId) {
  //  if (!shared) {
  //    this.props.showSelectorFn();
  //    return;
  //  }
  //}
    console.log('model sharing =', shared);
    this.setState({
      title: model.name,
      shared: shared,
      owned: model.ownerId === this.props.userId,
      model: model,
    });
  }

  async copyModelAsync() {
    if (this.props.userId === '') {
      this.props.showLoginCopyFlow(this.props.modelId);
    }
    let modelId = await copyModel(this.props.modelId, this.props.userId);
    this.props.selectModel(modelId);
  }

  pickModel() {
    this.props.showSelectorFn();
  }

  logout() {
  }

	updateTitle(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      title: e.target.value,
    });
  }

	setTitle(e: React.ChangeEvent<HTMLInputElement>) {
    try {
      API.graphql(graphqlOperation(
          updateModel,
          { input: {id: this.props.modelId,
                    name: e.target.value}
          }));
    } catch (e) {
      console.log(e);
    }
  }

  showDeleteModel() {
    this.setState({
      showDeleteModel: true,
    });
  }

  dismissDeleteModel() {
    this.setState({
      showDeleteModel: false,
    });
  }

  async deleteModel() {
    const model = await getPrivateOrPublicModel(this.props.modelId);
    if (model.publishedModelId !== null) {
      let deleteResponse = await API.graphql({
          query: deletePublishedModel,
          variables: { input: {
            id: model.publishedModelId,
          }}});
    }
    let deleteResponse = await API.graphql({
        query: deleteModel,
        variables: { input: {
          id: this.props.modelId,
        }}});
    this.setState({
      showDeleteModel: false,
    });
    this.pickModel();
  }

  render() {
    return (
      <div className='headerBar'>
        <a href="/"><div className='thymeLogo headerColor' onClick={this.simulatoryClickBound}>Simulatory</div></a>
        {this.props.modelId === '' ? <div></div> :
        <div className='headerMiddle'>
          <Hideable hidden={!this.state.showPrefabModal && this.state.model !== null}>
            <PrefabModal
              model={this.state.model!}
              dismissModal={this.dismissPrefabBound}
              userId={this.props.userId}
            />
          </Hideable>
          <Hideable hidden={!this.state.showDeleteModel}>
            <div onClick={this.dismissDeleteModelBound} className="overlay">
            </div>
            <div className="deleteModelBox">
              Really delete {this.state.title}?
              <div className="deleteModelRow">
                <button onClick={this.deleteModelBound}>Yes, permanently delete it</button>
                <button onClick={this.dismissDeleteModelBound}>No</button>
              </div>
            </div>
          </Hideable>
          <div className='headerMiddleTop'>
            <div className='modelTitle headerColor'>
              <input
                className='modelTitle'
                value={this.state.title}
                onChange={this.updateTitleBound}
                onBlur={this.setTitleBound}
                placeholder='Untitled Model'
              />
              {this.state.owned ? '(saved)' : <div className='notSaved'>(Someone else owns this model; make a copy to save.)</div>}
            </div>
          </div>
          <div className='headerMiddleBottom'>
            <button
                className='button7'
                onClick={this.pickModelBound}>
              Open Another
            </button>
            <button
                className='button7'
                onClick={this.toggleShareBound}>
              {this.state.shared ?
                  'Model is public to those with the link. Click to hide.' :
                  'Model is private. Click to share.'}
            </button>
            <button
                className='button7'
                onClick={this.createPrefabBound}>
              Create Model Component
            </button>
            <button
                className='button7'
                onClick={this.copyModelBound}>
              Copy
            </button>
            {!this.state.owned ? '' :
              <button
                  className='button7'
                  onClick={this.showDeleteModelBound}>
                Delete
              </button>
            }
          </div>
        </div>
        }
        <button
          onClick={this.props.userId === '' ? this.props.showLoginFn : signOut}
          className="logoutButton"
        >
          {this.props.userId === '' ? 'Log In' : 'Logout ' + this.props.userId}
        </button>
      </div>
    );
  }
}
