import React, { Component, RefObject } from 'react';
import { parse, } from 'mathjs';
import { CellContent } from './cellType';
import { SelectedCell } from './cellType';
import { EvalFunction } from 'mathjs';

export type CellProps = {
  updateFn: (row: number, column: number, cellContent: CellContent) => void,
  row: number,
  column: number,
  isDefault: boolean,
  formula: string,
  showContextMenuFn: (x: number, y: number, contextMenuRow: number, contextMenuColumn: number) => void,
  error: boolean,
  selectCell: (row: number, column: number) => void,
  selectedCell: SelectedCell | null,
  updateSelectedCellContents: (formula: string) => void,
  gridIndex: number,
};

export type CellState = {
  formula: string
  error: boolean,
  default: boolean
};

export type Scope = Map<string, number>;

export class Cell extends Component<CellProps, CellState> {
  handleChangeBound: (event: any)  => void;
  handleBlurBound: (event: any)  => void;
  onClickBound: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  nameInput: RefObject<HTMLInputElement>;
  onKeyDownBound: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  constructor(props: CellProps) {
    super(props);
    this.handleChangeBound = this.handleChange.bind(this);
    this.handleBlurBound = this.handleBlur.bind(this);
    this.onClickBound = this.onClick.bind(this);
    this.onKeyDownBound = this.onKeyDown.bind(this);
    this.state = {
      formula: props.formula,
      error: false,
      default: props.isDefault,
    };

    this.nameInput = React.createRef();
  }

	handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value === this.props.formula) return;
    this.sendChange(e.target.value);
  }

  // TODO: move this up to the model class.
  sendChange(formula: string) {
    let evalFn: EvalFunction | null = null;
    try {
      evalFn = parse(formula).compile();
    } catch (e) {
      this.setState({
        error: true,
      });
      console.log('error');
      return;
    }
    if (evalFn) {
      console.log('sending update for', this.props.row, ', ', this.props.column, ' = ', formula);
      this.props.updateFn(this.props.row, this.props.column, {
        compiledNode: evalFn,
        formula: formula,
        isDefault: false,
        hasExecutionError: false,
        errors: [],
      });
      this.setState({
        formula: formula,
        default: false,
        error: false,
      });
    }
	}

  componentDidUpdate(prevProps: CellProps, prevState: CellState, snapshot: any) {
    //console.log('cell updated: ', this.props.row, ',', this.props.column);
    if (prevProps.formula !== this.props.formula) {
      this.setState({formula: this.props.formula});
    } else if (this.props.selectedCell !== null &&
               this.props.selectedCell.row === this.props.row &&
               this.props.selectedCell.col === this.props.column) {
      if (this.state.formula !== this.props.selectedCell.formula) {
        this.setState({formula: this.props.selectedCell.formula});
      }
      if (prevProps.selectedCell === null ||
          prevProps.selectedCell.row !== this.props.selectedCell.row ||
          prevProps.selectedCell.col !== this.props.selectedCell.col) {
        if (this.nameInput.current !== null) {
          this.nameInput.current.focus();
        }
      }
    }
  }

  contextMenu(e: any) {
    e.preventDefault();
    this.props.showContextMenuFn(e.clientX, e.clientY, this.props.row, this.props.column);
  }

  handleChange(event: any) {
    this.props.updateSelectedCellContents(event.target.value);
    // While this duplicates the data flow through selected cell, it's
    // necessary to avoid the cursor jumping around. React has some special
    // magic so that going straight from change->state triggers a re-render
    // before the DOM changes, whereas if the data moves outside the component,
    // we don't get this special fast update. The DOM then updates back to the
    // original value and then to the new state, putting the cursor at the end
    // of the input.
    this.setState({
      formula: event.target.value,
    });
  }

  onClick(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    if (this.state.formula !== this.props.formula) {
      this.sendChange(this.state.formula);
    }
    this.props.selectCell(this.props.row, this.props.column);
  }

  onKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (this.props.selectedCell === null) return;
    if (e.keyCode === 0x25) {  // left
      //this.props.selectCell(this.props.row, this.props.column - 1);
    } else if (e.keyCode === 0x26) {  // up
      this.props.selectCell(this.props.row - 1, this.props.column);
    } else if (e.keyCode === 0x27) {  // right
      //this.props.selectCell(this.props.row, this.props.column + 1);
    } else if (e.keyCode === 0x28) {  // down
      this.props.selectCell(this.props.row + 1, this.props.column);
    }
  }

	render() {
    return (
        <div className='Column-div'>
          <input
            ref={this.nameInput}
            onKeyDown={this.onKeyDownBound}
            className={(this.state.error || this.props.error ? 'CellInputError Cell' : 'CellOk Cell isDefault' + this.props.isDefault)
              + ' Cell' + this.props.gridIndex + '-' + this.props.row + '-' + this.props.column
            }
            value={this.state.formula}
            placeholder='input formula'
            onContextMenu={this.contextMenu.bind(this)}
            onChange={this.handleChangeBound}
            onClick={this.onClickBound}
            onBlur={this.handleBlurBound} />
        </div>
    );
  }
};
