import React, { Component } from 'react';
import { ADD_ROW_ABOVE, ADD_ROW_BELOW, REMOVE_ROW, ADD_COLUMN_LEFT, ADD_COLUMN_RIGHT, REMOVE_COLUMN, INSERT_MODEL } from './cellType';
import { RenderIf } from './renderIf';

type ContextMenuProps = {
  visible: boolean,
  x: number,
  y: number,
	emitSelectionFn: (option: number) => void,
	dismissContextBox: () => void,
  enableColumnMutation: boolean,
}

type ContextMenuState = {
}

export class ContextMenu extends Component<ContextMenuProps, ContextMenuState> {
  render() {
    if (this.props.visible) {
      return (
        <div>
          <div className="overlay" onClick={this.props.dismissContextBox}>
          </div>
          <div
              className="contextMenu"
              style={{left: this.props.x + 5, top: this.props.y + 5}}>
            <div className="contextMenu--option" onClick={this.props.emitSelectionFn.bind(null, ADD_ROW_ABOVE)}>Add Row Above</div>
            <div className="contextMenu--option" onClick={this.props.emitSelectionFn.bind(null, ADD_ROW_BELOW)}>Add Row Below</div>
            <div className="contextMenu--option" onClick={this.props.emitSelectionFn.bind(null, REMOVE_ROW)}>Remove Row</div>
            <div className="contextMenu--option" onClick={this.props.emitSelectionFn.bind(null, INSERT_MODEL)}>Replace this row with another model</div>
            <RenderIf render={this.props.enableColumnMutation}>
              <div className="contextMenu--option" onClick={this.props.emitSelectionFn.bind(null, ADD_COLUMN_LEFT)}>Add Column Left</div>
              <div className="contextMenu--option" onClick={this.props.emitSelectionFn.bind(null, ADD_COLUMN_RIGHT)}>Add Column Right</div>
              <div className="contextMenu--option" onClick={this.props.emitSelectionFn.bind(null, REMOVE_COLUMN)}>Remove Column</div>
            </RenderIf>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
};
