/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createModel = /* GraphQL */ `
  mutation CreateModel(
    $input: CreateModelInput!
    $condition: ModelModelConditionInput
  ) {
    createModel(input: $input, condition: $condition) {
      id
      ownerId
      name
      description
      columns
      initializerGrid
      updateGrid
      public
      publishedModelId
      selectedVariables
      inputVariables
      outputVariables
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateModel = /* GraphQL */ `
  mutation UpdateModel(
    $input: UpdateModelInput!
    $condition: ModelModelConditionInput
  ) {
    updateModel(input: $input, condition: $condition) {
      id
      ownerId
      name
      description
      columns
      initializerGrid
      updateGrid
      public
      publishedModelId
      selectedVariables
      inputVariables
      outputVariables
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteModel = /* GraphQL */ `
  mutation DeleteModel(
    $input: DeleteModelInput!
    $condition: ModelModelConditionInput
  ) {
    deleteModel(input: $input, condition: $condition) {
      id
      ownerId
      name
      description
      columns
      initializerGrid
      updateGrid
      public
      publishedModelId
      selectedVariables
      inputVariables
      outputVariables
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPublishedModel = /* GraphQL */ `
  mutation CreatePublishedModel(
    $input: CreatePublishedModelInput!
    $condition: ModelPublishedModelConditionInput
  ) {
    createPublishedModel(input: $input, condition: $condition) {
      id
      ownerId
      name
      description
      columns
      initializerGrid
      updateGrid
      public
      publishedModelId
      selectedVariables
      inputVariables
      outputVariables
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePublishedModel = /* GraphQL */ `
  mutation UpdatePublishedModel(
    $input: UpdatePublishedModelInput!
    $condition: ModelPublishedModelConditionInput
  ) {
    updatePublishedModel(input: $input, condition: $condition) {
      id
      ownerId
      name
      description
      columns
      initializerGrid
      updateGrid
      public
      publishedModelId
      selectedVariables
      inputVariables
      outputVariables
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePublishedModel = /* GraphQL */ `
  mutation DeletePublishedModel(
    $input: DeletePublishedModelInput!
    $condition: ModelPublishedModelConditionInput
  ) {
    deletePublishedModel(input: $input, condition: $condition) {
      id
      ownerId
      name
      description
      columns
      initializerGrid
      updateGrid
      public
      publishedModelId
      selectedVariables
      inputVariables
      outputVariables
      createdAt
      updatedAt
      owner
    }
  }
`;
