import React, { Component } from 'react';
import { Cell } from './cell';
import { CellContent } from './cellType';
import { ModelGrid } from './modelGrid';
import { SelectedCell } from './cellType';

export type RowProps = {
  updateFn: (row: number, column: number, cellContent: CellContent) => void,
  selectVariableFn: (variableName: string) => void,
  selectedVariables: Array<string>,
  setVariableNameFn: (row: number, variableName: string) => void,
  row: number,
  gridIndex: number,
  grid: ModelGrid,
  showContextMenuFn: (x: number, y: number, contextMenuRow: number, contextMenuColumn: number) => void,
  variableName: string,
  selectCell: (row: number, column: number) => void,
  selectedCell: SelectedCell | null,
  updateSelectedCellContents: (formula: string) => void,
};

export type RowState = {
  placeholders: Array<string>,
  variableName: string,
};

export class Row extends Component<RowProps, RowState> {
  handleChangeBound: (event: any)  => void;
  handleBlurBound: (event: any)  => void;
  variableContextMenuBound: (e: any) => void;

  constructor(props: RowProps) {
    super(props);
    this.handleChangeBound = this.handleChange.bind(this);
    this.handleBlurBound = this.handleBlur.bind(this);
    this.variableContextMenuBound = this.variableContextMenu.bind(this);
    let placeholders = [];
    for (let i = 0; i < this.numColumns(); i++) {
      placeholders.push('');
    }

    this.state = {
      placeholders: placeholders,
      variableName: this.props.variableName,
    }
  }

  numColumns() {
    return this.props.grid.columns.length;
  }

	handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({variableName: e.target.value});
  }

	handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
    this.props.setVariableNameFn(this.props.row, e.target.value);
  }

  handleCheckboxChange(changeEvent: any): void {
    this.props.selectVariableFn(this.props.variableName);
  }

  componentDidUpdate(prevProps: RowProps, prevState: RowState, snapshot: any) {
    if (prevProps.row !== this.props.row ||
        prevProps.variableName !== this.props.variableName) {
      this.setState({variableName: this.props.variableName});
    }
  }

  variableContextMenu(e: any) {
    e.preventDefault();
    this.props.showContextMenuFn(e.clientX, e.clientY, this.props.row, -1);
  }

  render() {
    let columns = [];
    columns.push(
      <div className='Column-div' key='superwrapperrow'>
        <div className='variableName'>
          <input
            className={'Cell isDefault' + (this.props.variableName === '') +
              ' Variable' + this.props.gridIndex + '-' + this.props.row
            }
            placeholder={'Variable Name'}
            value={this.state.variableName === '' ? '' : this.state.variableName}
            onChange={this.handleChangeBound}
            onBlur={this.handleBlurBound}
            key="variableNameBox"
            onContextMenu={this.variableContextMenuBound}
          />
        </div>
      </div>
    );
    for (let ci = 0; ci < this.numColumns(); ci++) {
      columns.push(
        <Cell
          updateFn={this.props.updateFn}
          row={this.props.row}
          column={ci}
          key={"RowCell" + ci}
          formula={this.props.grid.columns[ci][this.props.row].formula}
          isDefault={this.props.grid.columns[ci][this.props.row].isDefault}
          error={this.props.grid.columns[ci][this.props.row].hasExecutionError}
          showContextMenuFn={this.props.showContextMenuFn}
          selectCell={this.props.selectCell}
          selectedCell={this.props.selectedCell}
          updateSelectedCellContents={this.props.updateSelectedCellContents}
          gridIndex={this.props.gridIndex}
        />
      );
    }
    return (<div className='Row-div'>
              <div className='Checkbox-div'>
                <input
                  className={"Checkbox" + this.props.gridIndex + '-' + this.props.row}
                  type='checkbox'
                  title="Plot this variable"
                  checked={this.props.selectedVariables.includes(this.props.variableName)}
                  onChange={this.handleCheckboxChange.bind(this)}
                />
              </div>
            {columns}
            </div>);
  }
};
