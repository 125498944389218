import React, { Component } from 'react';

type DecisionHeaderProps = {
  updateFn: (column: number, variableName: string) => void,
  addColumnFn: () => void,
  columns: Array<string>,
}

type DecisionHeaderState = {
  columns: Array<string>,
}

export class DecisionHeader extends Component<DecisionHeaderProps, DecisionHeaderState> {
  constructor(props: DecisionHeaderProps) {
    super(props);
    this.state = {
      columns: this.props.columns,
    }
  }

  componentDidUpdate(prevProps: DecisionHeaderProps, prevState: DecisionHeaderState, snapshot: any) {
    if (prevProps.columns !== this.props.columns) {
      this.setState({
        columns: this.props.columns,
      });
    }
  }
  

  addColumn() {
    this.props.addColumnFn();
  }

	handleChange(column: number, e: React.ChangeEvent<HTMLInputElement>) {
    let columns = this.state.columns;
    columns[column] = e.target.value;
    this.setState({columns: columns});
  }

	handleBlur(column: number, e: React.ChangeEvent<HTMLInputElement>) {
    this.props.updateFn(column, e.target.value);
  }

  render() {
    let columns = [];
    for (let ci = 0; ci < this.props.columns.length; ci++) {
      columns.push(
        <div className='Column-div' key={"decisionHeaderColumnDiv" + ci}>
          <input
            className='Cell isDefaultfalse'
            placeholder='Decision Name'
            value={this.state.columns[ci]}
            onChange={this.handleChange.bind(this, ci)}
            onBlur={this.handleBlur.bind(this, ci)}
            key={"decisionHeaderColumn" + ci}
          />
        </div>
      );
    }
    return (
      <div className='Row-div'>
        <div className='Checkbox-div'></div>
        <div className='Column-div'>
        </div>
        {columns}
      </div>
    );
  }
}
