import { parse, EvalFunction } from 'mathjs';

export const ADD_ROW_ABOVE = 0;
export const ADD_ROW_BELOW = 1;
export const REMOVE_ROW = 2;
export const ADD_COLUMN_LEFT = 3;
export const ADD_COLUMN_RIGHT = 4;
export const REMOVE_COLUMN = 5;
export const INSERT_MODEL = 6;

export type CellContent = {
  compiledNode: EvalFunction,
  formula: string,
  isDefault: boolean,
  hasExecutionError: boolean,
  errors: Array<string>,
}

export function defaultCellContent(): CellContent {
  return {
          compiledNode: parse('').compile(),
          formula: '',
          isDefault: true,
          hasExecutionError: false,
          errors: [],
  };
}

export type SelectedCell = {
  modelIndex: number,
  row: number,
  col: number,
  formula: string,
};

