import React, { Component } from 'react';
import { Grid } from './grid';
import { initialize, serializeGrid, insertRow, insertColumn, deleteRow,
    deleteColumn, resizeIfCellOutOfBounds, newModelGrid, updateModel,
    updateModelVariable, ModelGrid, appendTo,
    nextNonPrefabRow, VariableBindings} from './modelGrid';
import { DecisionHeader } from './decisionHeader';
import { CellContent } from './cellType';
import { ContextMenu } from './contextMenu';
import { Chart } from './chart';
import { ADD_ROW_ABOVE, ADD_ROW_BELOW, REMOVE_ROW, ADD_COLUMN_LEFT, ADD_COLUMN_RIGHT, REMOVE_COLUMN, INSERT_MODEL } from './cellType';
import API, {graphqlOperation} from '@aws-amplify/api';
import { getPrivateOrPublicModel } from './queries';
import { updateModel as gqlUpdateModel } from './graphql/mutations';
import { VariableEditor } from './variableEditor';
import { SelectedCell } from './cellType';
import { TermsOfUse } from './termsOfUse';

const CONTEXT_WIDTH = 50;
const CONTEXT_HEIGHT = 100;

type ModelProps = {
  modelId: string,
  userId: string,
  showLoginFn: () => void,
  copyModelToUser: string,
};

type Coordinates = {
  column: number,
  row: number,
};

type ModelState = {
  columns: Array<string>,
  grids: Array<ModelGrid>,
  selectedVariables: Array<string>,
  contextMenuGridIndex: number,
  contextMenuX: number,
  contextMenuY: number,
  contextMenuVisible: boolean,
	contextMenuRow: number,
	contextMenuColumn: number,
  owned: boolean,
  errorCells: Array<Array<Coordinates>>,
  selectedCell: SelectedCell | null,
}

const INITIALIZER_GRID = 0;
const INCREMENT_GRID = 1;

export class Model extends Component<ModelProps, ModelState> {
  appendModelBound: (name: string, initializerGrid: ModelGrid, updateGrid: ModelGrid,
                     inputVariables: Array<string>, outputVariables: Array<string>) => void;
  updateErrorCellsInitializerBound: (errorCells: Array<Coordinates>) => void;
  updateErrorCellsIncrementBound: (errorCells: Array<Coordinates>) => void;
  selectInitializerCellBound: (row: number, col: number) => void;
  selectIncrementCellBound: (row: number, col: number) => void;
  updateEvalFunctionBound: (gridIndex: number, row: number, column: number, cellContent: CellContent) => void;
  updateSelectedCellContentsBound: (formula: string) => void;
  dismissContextBoxBound: () => void;
  updateBindingsBound: (prefabName: string, inputBindings: VariableBindings, outputBindings: VariableBindings) => void;
  updatePrefabNameBound: (oldName: string, newName: string) => void;
  constructor(props: ModelProps) {
    super(props);
    this.state = {
      columns: ['Decision 1'],
      grids: [
        newModelGrid(),
        newModelGrid(),
      ],
      selectedVariables: [],
      contextMenuGridIndex: 0,
      contextMenuVisible: false,
      contextMenuX: 5,
      contextMenuY: 5,
			contextMenuRow: 0,
			contextMenuColumn: 0,
      owned: false,
      errorCells: [
        [],
        [],
      ],
      selectedCell: null,
    }
    this.dismissContextBoxBound = this.dismissContextBox.bind(this);
    this.appendModelBound = this.appendModel.bind(this);
    this.updateErrorCellsInitializerBound = this.updateErrorCells.bind(this, INITIALIZER_GRID);
    this.updateErrorCellsIncrementBound = this.updateErrorCells.bind(this, INCREMENT_GRID);
    this.selectInitializerCellBound = this.selectCell.bind(this, INITIALIZER_GRID);
    this.selectIncrementCellBound = this.selectCell.bind(this, INCREMENT_GRID);
    this.updateEvalFunctionBound = this.updateEvalFunction.bind(this);
    this.updateSelectedCellContentsBound = this.updateSelectedCellContents.bind(this);
    this.updateBindingsBound = this.updateBindings.bind(this);
    this.updatePrefabNameBound = this.updatePrefabName.bind(this);
  }

  getSelectedCell(modelIndex: number) {
    if (this.state.selectedCell === null) return null;
    if (this.state.selectedCell.modelIndex !== modelIndex) return null;
    return this.state.selectedCell;
  }

  updateErrorCells(gridIndex: number, cells: Array<Coordinates>) {
    let errorCells = [];
    for (let i = 0; i < this.state.errorCells.length; i++) {
      if (i === gridIndex) {
        errorCells.push(cells);
      } else {
        errorCells.push(this.state.errorCells[i]);
      }
    }
    this.setState({
      errorCells: errorCells,
    });
    // TODO: pass cells down. Set cells from chart/simulate
  }

  appendModel(name: string, initializerGrid: ModelGrid, updateGrid: ModelGrid,
              inputVariables: Array<string>, outputVariables: Array<string>) {
    let currentInitializer = this.state.grids[0];
    appendTo(name, currentInitializer, initializerGrid, inputVariables, outputVariables);
    let currentUpdater = this.state.grids[1];
    appendTo(name, currentUpdater, updateGrid, inputVariables, outputVariables);
    this.setState({
      grids: [
        currentInitializer,
        currentUpdater,
      ],
    });
  }

  async loadModel() {
    const model = await getPrivateOrPublicModel(this.props.modelId);
    if (model === null) return;
    let updates: any = {
      owned: model.ownerId === this.props.userId,
    };
    if (model.initializerGrid != null &&
        model.updateGrid != null) {
      updates.columns = model.columns === undefined ? '' : JSON.parse(model.columns);
      updates.grids = [
          initialize(model.initializerGrid),
          initialize(model.updateGrid),
      ];
    }
    let selectedVariables = model.selectedVariables;
    if (selectedVariables !== null && selectedVariables !== undefined) {
      updates.selectedVariables = selectedVariables;
    }
    this.setState(updates);
  }

  insertModelFlow() {
  }

	handleContextMenuClick(option: number) {
		let grid = this.state.grids[this.state.contextMenuGridIndex];
		switch (option) {
			case ADD_ROW_ABOVE:
				insertRow(grid, this.state.contextMenuRow);
        this.setState({
          grids: [this.state.grids[INITIALIZER_GRID], this.state.grids[INCREMENT_GRID]],
          selectedCell: null,
        });
        break;
			case ADD_ROW_BELOW:
				insertRow(grid, nextNonPrefabRow(grid, this.state.contextMenuRow));
        this.setState({
          grids: [this.state.grids[INITIALIZER_GRID], this.state.grids[INCREMENT_GRID]],
          selectedCell: null,
        });
        break;
			case REMOVE_ROW:
				deleteRow(grid, this.state.contextMenuRow);
        this.setState({
          grids: [this.state.grids[INITIALIZER_GRID], this.state.grids[INCREMENT_GRID]],
          selectedCell: null,
        });
        break;
      case INSERT_MODEL:
        this.insertModelFlow();
        break;
			case ADD_COLUMN_LEFT:
        for (let i = 0; i < this.state.grids.length; i++) {
          insertColumn(this.state.grids[i], this.state.contextMenuColumn);
        }
        this.state.columns.splice(this.state.contextMenuColumn, 0, '');
        this.setState({
          grids: [this.state.grids[INITIALIZER_GRID], this.state.grids[INCREMENT_GRID]],
          columns: this.state.columns,
          selectedCell: null,
        });
				break;
			case ADD_COLUMN_RIGHT:
        for (let i = 0; i < this.state.grids.length; i++) {
          insertColumn(this.state.grids[i], this.state.contextMenuColumn + 1);
        }
        this.state.columns.splice(this.state.contextMenuColumn + 1, 0, '');
        this.setState({
          grids: [this.state.grids[INITIALIZER_GRID], this.state.grids[INCREMENT_GRID]],
          columns: this.state.columns,
          selectedCell: null,
        });
				break;
			case REMOVE_COLUMN:
        for (let i = 0; i < this.state.grids.length; i++) {
          deleteColumn(this.state.grids[i], this.state.contextMenuColumn);
        }
        this.state.columns.splice(this.state.contextMenuColumn, 1);
        this.setState({
          grids: [this.state.grids[INITIALIZER_GRID], this.state.grids[INCREMENT_GRID]],
          columns: this.state.columns,
          selectedCell: null,
        });
				break;
			default:
				return;
		}
	}

  showContextMenu(gridIndex: number, x: number, y: number, contextMenuRow: number, contextMenuColumn: number) {
    this.setState({
      contextMenuGridIndex: gridIndex,
      contextMenuVisible: true,
      contextMenuX: x,
      contextMenuY: y,
			contextMenuRow: contextMenuRow,
			contextMenuColumn: contextMenuColumn,
    });
  }

  initializerGrid(): ModelGrid {
    return this.state.grids[INITIALIZER_GRID];
  }

  incrementGrid(): ModelGrid {
    return this.state.grids[INCREMENT_GRID];
  }

  updateSelectedVariable(selectedVariable: string) {
    let selected = this.state.selectedVariables;
    if (selected.includes(selectedVariable)) {
      delete selected[selected.indexOf(selectedVariable)];
    } else {
      selected = [selectedVariable];
      //selected.push(selectedVariable);
    }
    this.setState({
      selectedVariables: selected
    });
  }

  useDefault(gridIndex: number, column: number, row: number): string {
    let grid = this.state.grids[gridIndex];
    if (column === 0 || grid.columns[column - 1].length <= row) {
      return 'formula';
    }
    return 'copied from left';
  }

  setVariableName(gridIndex: number, row: number, variableName: string) {
    let grid = this.state.grids[gridIndex];
    updateModelVariable(grid, row, variableName);
    this.setState({
      grids: this.state.grids,
    });
  }

  updateEvalFunction(gridIndex: number, row: number, column: number, cellContent: CellContent) {
    let grid = this.state.grids[gridIndex];
    updateModel(grid, row, column, cellContent);
    console.log('update grids');
    this.setState({
      grids: [this.state.grids[INITIALIZER_GRID], this.state.grids[INCREMENT_GRID]],
    });
  }

  addColumn() {
    console.log('Add column');
    this.state.columns.push('');
    for (let i = 0; i < this.state.grids.length; i++) {
      resizeIfCellOutOfBounds(this.state.grids[i], 0, this.state.grids[i].columns.length);
    }
    this.setState({
      columns: this.state.columns
    });
  }

  updateColumn(column: number, decisionName: string) {
    let columnNames = this.state.columns;
    columnNames[column] = decisionName;
    this.setState({
      columns: columnNames
    });
  }

	componentDidMount() {
    this.loadModel();
	}

	dismissContextBox() {
		this.setState({
			contextMenuVisible: false,
		});
	}

  componentDidUpdate(prevProps: ModelProps, prevState: ModelState, snapshot: any) {
    if (this.state.owned) {
      this.saveModel();
    }
    if (this.props.copyModelToUser !== prevProps.copyModelToUser &&
        this.props.copyModelToUser !== '') {
      console.log('Copy model to user: ', this.props.copyModelToUser);
    }
  }

  selectCell(modelIndex: number, row: number, col: number) {
    if (col < 0) {
      return;
    } else if (col >= this.state.grids[modelIndex].columns.length) {
      return;
    }
    if (row < 0) {
      if (modelIndex === 0) return;
      modelIndex -= 1;
      row = this.state.grids[modelIndex].columns[col].length - 1;
    } else if (row >= this.state.grids[modelIndex].columns[col].length) {
      modelIndex += 1;
      row = 0;
      if (modelIndex >= this.state.grids.length) return;
    }
    let currentSelection = this.state.selectedCell;
    if (currentSelection !== null &&
        modelIndex === currentSelection.modelIndex &&
        row === currentSelection.row &&
        col === currentSelection.col) return;
    let formula: string = this.state.grids[modelIndex].columns[col][row].formula;
    this.setState({
      selectedCell: {
        modelIndex: modelIndex,
        row: row,
        col: col,
        formula: formula,
      },
    });
  }

  async saveModel() {
    try {
      console.log('updating model', this.props.modelId);
      let resp = await API.graphql(graphqlOperation(
          gqlUpdateModel,
          { input: {id: this.props.modelId,
                    columns: JSON.stringify(this.state.columns),
                    initializerGrid: serializeGrid(this.state.grids[INITIALIZER_GRID]),
                    updateGrid: serializeGrid(this.state.grids[INCREMENT_GRID]),
                    selectedVariables: this.state.selectedVariables,
                    }
          })) as any;
      console.log(resp);
    } catch (e) {
      console.log('Failed to save model:', e);
    }
  }

  updateSelectedCellContents(formula: string) {
    if (this.state.selectedCell === null) return;
    let selectedCell: SelectedCell = this.state.selectedCell;
    selectedCell.formula = formula;
    this.setState({
      selectedCell: selectedCell,
    });
  }

  updateBindings(prefabName: string, inputBindings: VariableBindings,
                 outputBindings: VariableBindings) {
    this.state.grids.forEach((grid: ModelGrid) => {
      grid.componentModelInputBindings.set(prefabName, inputBindings);
      grid.componentModelOutputBindings.set(prefabName, outputBindings);
    });
    this.setState({
      grids: this.state.grids,
    });
  }

  updatePrefabName(oldName: string, newName: string) {
    this.state.grids.forEach((grid: ModelGrid) => {
      for (let i = 0; i < grid.rowGroupNames.length; i++) {
        if (grid.rowGroupNames[i] === oldName) {
          grid.rowGroupNames[i] = newName;
        }
      }
      let oldBindings = grid.componentModelInputBindings.get(oldName);
      if (oldBindings !== undefined) {
        grid.componentModelInputBindings.set(newName, oldBindings);
        grid.componentModelInputBindings.delete(oldName);
      }
      oldBindings = grid.componentModelOutputBindings.get(oldName);
      if (oldBindings !== undefined) {
        grid.componentModelOutputBindings.set(newName, oldBindings);
        grid.componentModelOutputBindings.delete(oldName);
      }
    });
    this.setState({
      grids: this.state.grids,
    });
  }

  render() {
    console.log('APP COMPONENT RENDER');
    return (
      <div className='RootColumn'>
        <ContextMenu
          visible={this.state.contextMenuVisible}
          x={this.state.contextMenuX}
          y={this.state.contextMenuY}
          enableColumnMutation={this.state.contextMenuColumn >= 0}
          dismissContextBox={this.dismissContextBoxBound}
					emitSelectionFn={this.handleContextMenuClick.bind(this)}
        />
        <VariableEditor
          models={this.state.grids}
          selectedCell={this.state.selectedCell}
          updateFn={this.updateEvalFunctionBound}
          updateSelectedCellContents={this.updateSelectedCellContentsBound}
        />
        <div className="gapBehindVariableEditor"></div>
        <p>Initial Values</p>
        <div>
        <DecisionHeader
          updateFn={this.updateColumn.bind(this)}
          addColumnFn={this.addColumn.bind(this)}
          columns={this.state.columns}
        />
        </div>
        <Grid
          numColumns={this.state.columns.length}
          updateFn={this.updateEvalFunction.bind(this, INITIALIZER_GRID)}
          setVariableNameFn={this.setVariableName.bind(this, INITIALIZER_GRID)}
          selectedVariables={this.state.selectedVariables}
          selectVariableFn={this.updateSelectedVariable.bind(this)}
          showContextMenuFn={this.showContextMenu.bind(this, INITIALIZER_GRID)}
          grid={this.state.grids[0]}
          userId={this.props.userId}
          appendModelFn={this.appendModelBound}
          showLoginFn={this.props.showLoginFn}
          selectCell={this.selectInitializerCellBound}
          selectedCell={this.getSelectedCell(INITIALIZER_GRID)}
          updateSelectedCellContents={this.updateSelectedCellContentsBound}
          gridIndex={INITIALIZER_GRID}
          updateBindings={this.updateBindingsBound}
          updatePrefabName={this.updatePrefabNameBound}
        />
        <p className="UpdatesEachTimeHeader">Updates each year</p>
        <Grid
          numColumns={this.state.columns.length}
          updateFn={this.updateEvalFunction.bind(this, INCREMENT_GRID)}
          setVariableNameFn={this.setVariableName.bind(this, INCREMENT_GRID)}
          selectedVariables={this.state.selectedVariables}
          selectVariableFn={this.updateSelectedVariable.bind(this)}
          showContextMenuFn={this.showContextMenu.bind(this, INCREMENT_GRID)}
          grid={this.state.grids[1]}
          userId={this.props.userId}
          appendModelFn={this.appendModelBound}
          showLoginFn={this.props.showLoginFn}
          selectCell={this.selectIncrementCellBound}
          selectedCell={this.getSelectedCell(INCREMENT_GRID)}
          updateSelectedCellContents={this.updateSelectedCellContentsBound}
          gridIndex={INCREMENT_GRID}
          updateBindings={this.updateBindingsBound}
          updatePrefabName={this.updatePrefabNameBound}
        />
        <div className="chart">
          <Chart
            initializerGrid={this.state.grids[INITIALIZER_GRID]}
            incrementGrid={this.state.grids[INCREMENT_GRID]}
            columns={this.state.columns}
            selectedVariables={this.state.selectedVariables}
            duration={10}
          />
        </div>
        <TermsOfUse />
      </div>
    );
  }
};
