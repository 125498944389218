import React, { Component } from 'react';
import { Row } from './row';
import { PrefabRow } from './prefabRow';
import { CellContent } from './cellType';
import { ModelGrid, VariableBindings } from './modelGrid';
import { ModelSelector } from './modelSelector';
import API from '@aws-amplify/api';
import { getModel, } from './graphql/queries';
import { initialize } from './modelGrid';
import { SelectedCell } from './cellType';
import { Model } from './models/index.d';
import { getPrivateOrPublicModel } from './queries';

type GridProps = {
  numColumns: number,
  updateFn: (row: number, column: number, cellContent: CellContent) => void,
  selectVariableFn: (variableName: string) => void,
  selectedVariables: Array<string>,
  setVariableNameFn: (row: number, variableName: string) => void,
  grid: ModelGrid,
  appendModelFn: (name: string, initializeGrid: ModelGrid, updateGrid: ModelGrid,
                  inputVariables: Array<string>, outputVariables: Array<string>) => void,
  showContextMenuFn: (x: number, y: number, contextMenuRow: number, contextMenuColumn: number) => void,
  userId: string,
  showLoginFn: () => void,
  selectCell: (row: number, column: number) => void,
  selectedCell: SelectedCell | null,
  updateSelectedCellContents: (formula: string) => void,
  gridIndex: number,
  updateBindings: (prefabName: string, inputBindings: VariableBindings, outputBindings: VariableBindings) => void,
  updatePrefabName: (oldName: string, newName: string) => void,
}

type GridState = {
  showModelSelector: boolean,
}

export class Grid extends Component<GridProps, GridState> {
  insertModelBound: () => void;
  insertModelSelectionBound: (modelId: string) => void;
  addRowBound: () => void;
  dismissModelSelectorBound: () => void;

  constructor(props: GridProps) {
    super(props);
    this.insertModelBound = this.insertModel.bind(this);
    this.addRowBound = this.addRow.bind(this);
    this.insertModelSelectionBound = this.insertModelSelection.bind(this);
    this.dismissModelSelectorBound = this.dismissModelSelector.bind(this);
    this.state = {
      showModelSelector: false,
    }
  }

  numRows() {
    return this.props.grid.variableNames.length;
  }

  addRow() {
    this.props.setVariableNameFn(this.numRows(), '');
  }

  insertModel() {
    this.setState({
      showModelSelector: true,
    });
  }

  insertModelSelection(modelId: string) {
    this.loadModel(modelId);
  }

  async loadModel(modelId: string) {
    const model: Model = await getPrivateOrPublicModel(modelId);
    this.setState({
      showModelSelector: false,
    });
    if (model.initializerGrid === undefined ||
        model.updateGrid === undefined) return;
    let name = model.name;
    for (let i = 1; i < 10000; i++) {
      if (this.props.grid.rowGroupNames.includes(name)) {
        break;
      }
      name = model.name + i;
    }
    this.props.appendModelFn(
        name,
        initialize(model.initializerGrid),
        initialize(model.updateGrid),
        model.inputVariables === undefined ? [] : model.inputVariables,
        model.outputVariables === undefined ? [] : model.outputVariables
    );
  }

  renderRowsList(name: string, rows: any, z: number) {
    if (name !== '') {
      return <div
          key={name + z}
          className="gridOutline">
          <div>{name}</div>
          {rows}
        </div>;
    } else {
      return <div
          key={name + z}>
          {rows}
        </div>;
    }
  }

  dismissModelSelector() {
		this.setState({
			showModelSelector: false,
    });
	}

  render () {
    let parts = [];
    if (this.state.showModelSelector) {
      parts.push(<div onClick={this.dismissModelSelectorBound} className="overlay"></div>);
      parts.push(<ModelSelector
        selectModelFn={this.insertModelSelectionBound}
        ownerId={this.props.userId}
        showLoginFn={this.props.showLoginFn}
        prefab={true}
        />);
    }
    let rows = [];
    let lastName = '';
    for (let i = 0; i < this.numRows(); i++) {
    //if (this.props.grid.rowGroupNames[i] !== lastName) {
    //  parts.push(this.renderRowsList(lastName, rows, parts.length));
    //  lastName = this.props.grid.rowGroupNames[i];
    //  rows = [];
    //}
      const rowGroupName = this.props.grid.rowGroupNames[i];
      if (rowGroupName === '') {
        rows.push(<Row
          updateFn={this.props.updateFn.bind(null)}
          selectedVariables={this.props.selectedVariables}
          selectVariableFn={this.props.selectVariableFn}
          setVariableNameFn={this.props.setVariableNameFn.bind(null)}
          grid={this.props.grid}
          variableName={this.props.grid.variableNames[i]}
          row={i}
          key={'row' + i}
          showContextMenuFn={this.props.showContextMenuFn}
          selectCell={this.props.selectCell}
          selectedCell={this.props.selectedCell}
          updateSelectedCellContents={this.props.updateSelectedCellContents}
          gridIndex={this.props.gridIndex}
        />);
      } else {
        let firstRow = i;
        let lastRow = firstRow + 1;
        for (; this.props.grid.rowGroupNames[lastRow] === rowGroupName; lastRow++) {
        }
        i = lastRow - 1;
        rows.push(<PrefabRow
          key={firstRow}
          rowStart={firstRow}
          rowEnd={lastRow}
          gridIndex={this.props.gridIndex}
          grid={this.props.grid}
          updateBindings={this.props.updateBindings}
          updatePrefabName={this.props.updatePrefabName}
          showContextMenuFn={this.props.showContextMenuFn}
        />);
      }
    }
    parts.push(this.renderRowsList(lastName, rows, parts.length));
    parts.push(<button className='bodyButton' key='insertButton' onClick={this.insertModelBound}>Insert Model</button>);
    return <div>{parts}</div>;
  }
};
