import React, { Component } from 'react';
import { ModelGrid } from './modelGrid';
import { parse, } from 'mathjs';
import { EvalFunction } from 'mathjs';
import { CellContent, SelectedCell } from './cellType';

export type VariableEditorProps = {
  models: Array<ModelGrid>,
  selectedCell: SelectedCell | null,
  updateFn: (gridIndex: number, row: number, column: number, cellContent: CellContent) => void,
  updateSelectedCellContents: (formula: string) => void,
};

export type VariableEditorState = {
  formula: string,
  errors: Array<string>,
};

function getVariableName(props: VariableEditorProps) {
  let selectedCell = props.selectedCell;
  if (selectedCell === null) return '';
  return props.models[selectedCell.modelIndex].variableNames[selectedCell.row];
}

export class VariableEditor extends Component<VariableEditorProps,
    VariableEditorState> {
  handleChangeBound: (event: any)  => void;
  handleBlurBound: (event: any)  => void;
  constructor(props: VariableEditorProps) {
    super(props);
    this.state = {
      formula: '',
      errors: [],
    };
    this.handleChangeBound = this.handleChange.bind(this);
    this.handleBlurBound = this.handleBlur.bind(this);
  }

  componentDidUpdate(prevProps: VariableEditorProps, prevState: VariableEditorState, snapshot: any) {
    if (prevProps !== this.props) {
      let selectedCell = this.props.selectedCell;
      if (selectedCell === null) return;
      this.setState({
        formula: selectedCell.formula,
        errors: this.props.models[selectedCell.modelIndex].columns[selectedCell.col][selectedCell.row].errors,
      });
    }
  }

  handleChange(event: any) {
    this.props.updateSelectedCellContents(event.target.value);
    // Needed to avoid cursor resets, though the same data goes through
    // updateSelectedCellContents.
    this.setState({
      formula: event.target.value,
    });
  }

  handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
    if (this.props.selectedCell === null) return;
    let formula: string = e.target.value;
    //if (formula === props.models[selectedCell.modelIndex].columns[selectedCell.col][selectedCell.row].formula) return;
    let evalFn: EvalFunction | null = null;
    try {
      evalFn = parse(formula).compile();
    } catch (e) {
    //this.setState({
    //  error: true,
    //});
      console.log('error');
      return;
    }
    if (evalFn) {
      let selectedCell: SelectedCell = this.props.selectedCell;
      this.props.updateFn(selectedCell.modelIndex, selectedCell.row, selectedCell.col, {
        compiledNode: evalFn,
        formula: formula,
        isDefault: false,
        hasExecutionError: false,
        errors: [],
      });
    }
  }

  render() {
    return (
      <div className="variableEditorBox">
        <div className="variableEditorRow">
          <div className="variableName">{getVariableName(this.props)} = </div>
          <input
            disabled={this.props.selectedCell === null}
            className="variableEditorInput"
            onChange={this.handleChangeBound}
            onBlur={this.handleBlurBound}
            value={this.state.formula}
          />
        </div>
        {this.state.errors.length === 0 ? '' :
          <div className="variableEditorErrors">Error: {this.state.errors[0]}</div>
        }
      </div>
    );
  }
};
