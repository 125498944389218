import API from '@aws-amplify/api';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
import { getModel, getPublishedModel, listModels, listPublishedModels } from './graphql/queries';
import { createModel } from './graphql/mutations';
import { Model } from './models/index.d';

export async function getPrivateOrPublicModel(modelId: string): Promise<Model> {
  try {
    const privateModelResponse = await API.graphql({ query: getModel,
        variables: {id: modelId} }) as any;
    if (privateModelResponse.data.getModel !== null) {
      return privateModelResponse.data.getModel;
    }
  } catch (error) {
    console.log(error);
  }
  const publicModelResponse = await API.graphql({ query: getPublishedModel,
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
      variables: {id: modelId} }) as any;
  return publicModelResponse.data.getPublishedModel;
}

export async function getPrivateModels(ownerId: string): Promise<Array<Model>> {
  try {
    const response = await API.graphql({ query: listModels,
        variables: {ownerId: ownerId} }) as any;
    if (!response.data) return Array<Model>();
    return response.data.listModels.items;
  } catch (error) {
    console.log(error);
    return Array<Model>();
  }
}

export async function getPublicModels(): Promise<Array<Model>> {
  try {
    const response = await API.graphql({ query: listPublishedModels,
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
        }) as any;
    console.log("Public models", response);
    if (!response.data) return Array<Model>();
    return response.data.listPublishedModels.items;
  } catch (error) {
    console.log(error);
    return Array<Model>();
  }
}

export async function copyModel(modelId: string, userId: string): Promise<string> {
  let model = await getPrivateOrPublicModel(modelId);
  let response = await API.graphql({ query: createModel,
    variables: { input: {
        ownerId: userId,
        name: 'Copy of ' + model.name,
        columns: model.columns,
        initializerGrid: model.initializerGrid,
        updateGrid: model.updateGrid,
        selectedVariables: model.selectedVariables
    }}}) as any;
  return response.data.createModel.id;
}
