import React, { Component } from 'react';
import { Auth } from 'aws-amplify'
import { Hideable } from './hideable';
import { copyModel } from './queries';

type LoginProps = {
  hideLogin: (selectedModelId?: string) => void,
  hidden: boolean,
  copyModelId: string,
}

enum LoginMode {
  LogIn,
  SignUp,
  ConfirmSignUp,
}

type LoginState = {
  username: string,
  password: string,
  verifyPassword: string,
  // Whether to show a login or sign up form
  login: LoginMode,
	errors: string,
  email: string,
  signupCode: string,
  resetCode: string,
  forgotPassword: boolean,
}

export class Login extends Component<LoginProps, LoginState> {
  handleInputChangeBound: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmitBound: (event: React.FormEvent<HTMLFormElement>) => void;
  handleConfirmSignUpBound : (event: React.FormEvent<HTMLFormElement>) => void;
	cancelLoginBound: () => void;
  setLoginBound: () => void;
  setSignUpBound: () => void;
  handleSignUpBound: (e: React.SyntheticEvent) => void;
  handleBlurBound: (event: React.ChangeEvent<HTMLInputElement>) => void;
  forgotPasswordBound: () => void;

	cancelLogin() {
    this.props.hideLogin();
  }

  constructor(props: LoginProps) {
    super(props);
    this.handleInputChangeBound = this.handleInputChange.bind(this);
    this.handleSubmitBound = this.handleSubmit.bind(this);
    this.cancelLoginBound = this.cancelLogin.bind(this);
    this.setLoginBound = this.setLogin.bind(this, LoginMode.LogIn);
    this.setSignUpBound = this.setLogin.bind(this, LoginMode.SignUp);
    this.handleSignUpBound = this.handleSignUp.bind(this);
    this.handleConfirmSignUpBound = this.handleConfirmSignUp.bind(this);
    this.handleBlurBound = this.handleBlur.bind(this);
    this.forgotPasswordBound = this.forgotPassword.bind(this);
    this.state = {
        username: '',
        password: '',
        verifyPassword: '',
        email: '',
				errors: '',
        login: LoginMode.SignUp,
        signupCode: '',
        resetCode: '',
        forgotPassword: false,
    };
  }

  setLogin(login: LoginMode) {
    this.setState({
      login: login,
    });
  }

  handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    this.handleSubmitAsync();
  }

  async handleSubmitAsync() {
    try {
      if (this.state.forgotPassword) {
        await Auth.forgotPasswordSubmit(this.state.username,
            this.state.resetCode, this.state.password);
      }
      const user = await Auth.signIn(this.state.username, this.state.password);
      let selectedModel: string | undefined = undefined;
      if (this.props.copyModelId !== '') {
        selectedModel = await copyModel(this.props.copyModelId, user.username);
      }
      this.props.hideLogin(selectedModel);
    } catch (error) {
      if (error.code === 'UserNotConfirmedException') {
        this.setState({
          login: LoginMode.ConfirmSignUp,
        });
        return;
      }
      console.log('Failed to sign in', error);
      this.setState({
        errors: 'Failed to sign in: ' + error.message,
      });
      return;
    }
  }

  handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.target;
    const value = target.value;
    if (target.name === 'password') {
      this.setState({
        password: value
      });
    } else if (target.name === 'verifyPassword') {
      this.setState({
        verifyPassword: value
      });
    } else if (target.name === 'email') {
      this.setState({
        email: value
      });
    } else if (target.name === 'username') {
      this.setState({
        username: value
      });
    } else if (target.name === 'signupCode') {
      this.setState({
        signupCode: value
      });
    } else if (target.name === 'resetCode') {
      this.setState({
        resetCode: value
      });
    }
  }

  setError(error: string) {
    this.setState({
      errors: error,
    });
  }

  handleBlur(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.target;
    if (target.name === 'password' ||
        target.name === 'verifyPassword') {
      if (this.state.password !== this.state.verifyPassword) {
        this.setError('Passwords do not match');
      } else {
        this.setError('');
      }
    }
  }

  validate() {
    let error: string = '';
    if (this.state.password !== this.state.verifyPassword) {
      error = 'Passwords do not match';
    }
    if (this.state.password.length < 6) {
      error = 'Password is too short';
    }
    if (!this.state.email.includes('@')) {
      error = 'Invalid email address';
    }
  }

  handleSignUp(e: React.SyntheticEvent) {
    e.preventDefault();
    this.handleSignUpAsync();
  }

  async handleSignUpAsync() {
    try {
      let email = this.state.email;
      let attributes = { email };
      console.log('Signing up');
			await Auth.signUp({
          username: this.state.username,
          password: this.state.password,
          attributes: attributes,
      });
      console.log('Signed up');
      this.setState({
        login: LoginMode.ConfirmSignUp,
      });
    } catch (error) {
      console.log('Failed to sign up', error);
      this.setState({
        errors: 'Failed to sign up: ' + error.message,
      });
      return;
    }
  }

  handleConfirmSignUp(e: React.SyntheticEvent) {
    e.preventDefault();
    this.handleConfirmSignUpAsync(this.state.username, this.state.signupCode);
  }

  async handleConfirmSignUpAsync(username: string, code: string) {
    try {
      await Auth.confirmSignUp(username, code);
      const user = await Auth.signIn(this.state.username, this.state.password);
      let selectedModel: string | undefined = undefined;
      if (this.props.copyModelId !== '') {
        selectedModel = await copyModel(this.props.copyModelId, user.username);
      }
      this.props.hideLogin(selectedModel);
    } catch (error) {
      console.log('error confirming sign up', error);
      this.setState({
        errors: 'Invalid confirmation code. Check your email.',
      });
    }
  }

  forgotPassword() {
    Auth.forgotPassword(this.state.username)
      .then(data => {
          this.setState({
            forgotPassword: true,
          });
        }
      )
      .catch(error => {
          this.setState({
            errors: 'Could not retrieve password: ' + error.message,
          });
        }
      );
  }

  render() {
    if (this.props.hidden) {
      return null;
    }
    return (
      <div>
        <div className="login">
          <div className="loginHeader">
            <div className={'loginTab ' + (this.state.login === LoginMode.LogIn ? '' : 'selected')}
              onClick={this.setSignUpBound}
            >Sign Up</div>
            <div className={'loginTab ' + (this.state.login === LoginMode.LogIn ? 'selected' : '')}
              onClick={this.setLoginBound}
            >Login</div>
          </div>
          <Hideable
            hidden={this.state.login !== LoginMode.LogIn}
          >
            <form id="login-form"
              className='loginForm'
              onSubmit={this.handleSubmitBound}
            >
              <input
                  type="text"
                  name="username"
                  value={this.state.username}
                  id="username-field"
                  className="login-form-field"
                  placeholder="Username"
                  onChange={this.handleInputChangeBound}
                  onBlur={this.handleBlurBound}
              />
              <input
                  type="password"
                  value={this.state.password}
                  name="password"
                  id="password-field"
                  className="login-form-field"
                  placeholder="Password"
                  onChange={this.handleInputChangeBound}
                  onBlur={this.handleBlurBound}
              />
              <Hideable hidden={!this.state.forgotPassword}>
                Check your email for a reset code.
                <input
                    type="text"
                    value={this.state.resetCode}
                    name="resetCode"
                    id="reset-code-field"
                    className="login-form-field"
                    placeholder="Reset Code"
                    onChange={this.handleInputChangeBound}
                    onBlur={this.handleBlurBound}
                />
              </Hideable>
              <input type="submit" value="Login" className="bodyButton"
                  id="login-form-submit"
                  disabled={
                      this.state.username === '' ||
                      (this.state.forgotPassword && this.state.resetCode === '') ||
                      this.state.password.length < 6}
              />
              <Hideable hidden={!this.state.errors}>
                <div className="errors">{this.state.errors}</div>
                <button className="bodyButton" onClick={this.forgotPasswordBound}>
                  Forgot Password? Click to get a reset code emailed.
                </button>
              </Hideable>
            </form>
          </Hideable>
          <Hideable
            hidden={this.state.login !== LoginMode.SignUp}
          >
            <form id="login-form"
              className='loginForm'
              onSubmit={this.handleSignUpBound}
            >
              <input
                  type="text"
                  name="username"
                  value={this.state.username}
                  id="username-field"
                  className="login-form-field"
                  placeholder="Username"
                  onChange={this.handleInputChangeBound}
                  onBlur={this.handleBlurBound}
              />
              <input
                  type="text"
                  name="email"
                  value={this.state.email}
                  id="email-field"
                  className="login-form-field"
                  placeholder="Email Address"
                  onChange={this.handleInputChangeBound}
                  onBlur={this.handleBlurBound}
              />
              <input
                  type="password"
                  value={this.state.password}
                  name="password"
                  id="password-field"
                  className="login-form-field"
                  placeholder="Password"
                  onChange={this.handleInputChangeBound}
                  onBlur={this.handleBlurBound}
              />
              <input
                  type="password"
                  value={this.state.verifyPassword}
                  name="verifyPassword"
                  id="password-field"
                  className="login-form-field"
                  placeholder="Verify Password"
                  onChange={this.handleInputChangeBound}
                  onBlur={this.handleBlurBound}
              />
              <input type="submit" value="Sign Up" className="bodyButton" id="login-form-submit"
                  disabled={this.state.errors !== '' ||
                      this.state.username === '' ||
                      this.state.password.length < 6 ||
                      this.state.email === ''}
              />
              <div className="errors">{this.state.errors}</div>
            </form>
          </Hideable>
          <Hideable
            hidden={this.state.login !== LoginMode.ConfirmSignUp}
          >
            <form id="login-form"
              className='loginForm'
              onSubmit={this.handleConfirmSignUpBound}
            >
              Enter the confirmation code sent to your email address.
              <input
                  type="text"
                  name="signupCode"
                  value={this.state.signupCode}
                  id="confirm-code-field"
                  className="login-form-field"
                  placeholder="Verification Code"
                  onChange={this.handleInputChangeBound}
              />
              <input className="bodyButton" type="submit" value="Confirm Sign Up" id="confirm-form-submit"
              />
            </form>
            <div className="errors">{this.state.errors}</div>
          </Hideable>
        </div>
        <div className="overlay" onClick={this.cancelLoginBound}></div>
      </div>
    );
  }
}
