/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:65dc332d-aa95-45b7-96d9-e5578da97f43",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_jJT2F642P",
    "aws_user_pools_web_client_id": "6ef2412ah6vbci6vb7jcrag8f3",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://2ovkv7tcsze27evadzfr2pojie.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-rs4qloikxngm7knlsloqsqk4je"
};


export default awsmobile;
