import { Component } from 'react';

type RenderIfProps = {
  render: boolean,
}

type RenderIfState = {
}

export class RenderIf extends Component<RenderIfProps, RenderIfState> {
  render() {
    if (!this.props.render) return null;
    return this.props.children;
  }
}
