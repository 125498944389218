import './App.css';
//import { withAuthenticator } from '@aws-amplify/ui-react'
import { ModelRouter } from './modelRouter';
import CookieConsent from 'react-cookie-consent';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <ModelRouter />
        <CookieConsent>This website uses cookies to enhance the user experience</CookieConsent>
      </header>
    </div>
  );
}

export default App;

//export default withAuthenticator(App);
