import React, { Component } from 'react';
import { Model } from './model';
import { ModelSelector } from './modelSelector';
import { PageHeader } from './pageHeader';
import { Login } from './login';
import { Auth } from 'aws-amplify';
import { getPublicModels } from './queries';
import { Hideable } from './hideable';
import Joyride from 'react-joyride';
import { getJoyrideFromHash } from './joyrides';

type ModelRouterProps = {
}
type ModelRouterState = {
  showSelector: boolean,
  showLogin: boolean,
  selectedModelId: string,
  userId: string,
  copyModelId: string,
  copyModelToUser: string,
}

export class ModelRouter extends Component<ModelRouterProps, ModelRouterState> {
  selectModelBound: (modelId: string) => void;
  showSelectorBound: () => void;
  showLoginBound: () => void;
  showLoginCopyFlowBound: (modelId: string) => void;
  hideLoginBound: (selectedModelId?: string) => void;

  constructor(props: ModelRouterProps) {
    super(props);
    this.state = {
      showSelector: true,
      showLogin: false,
      selectedModelId: '',
      userId: '',
      copyModelId: '',
      copyModelToUser: '',
    };

    this.selectModelBound = this.selectModel.bind(this);
    this.showSelectorBound = this.showSelector.bind(this);
    this.showLoginBound = this.showLogin.bind(this);
    this.showLoginCopyFlowBound = this.showLoginCopyFlow.bind(this);
    this.hideLoginBound = this.hideLogin.bind(this);
  }

  componentDidMount() {
    this.readAuthorizedUser();
    document.title = "Simulatory"
  }

  readAuthorizedUser() {
    let loggedIn = false;
    Auth.currentSession()
      .then(data => {
        console.log(data)
        let modelId = this.getModelIdFromHash();
        if (data.getAccessToken().payload.username !== "") {
          loggedIn = true;
        }
        this.setState({
          userId: data.getAccessToken().payload.username,
          showSelector: modelId === undefined,
          selectedModelId: modelId === undefined ? '' : modelId,
        });
      })
      .catch(error => {
        let modelId = this.getModelIdFromHash();
        this.setState({
          userId: '',
          showSelector: modelId === undefined,
          selectedModelId: modelId === undefined ? '' : modelId,
        });
      });
  //if (!loggedIn) {
  //  Auth.currentCredentials()
  //  .then (anon => {
  //    console.log('Tried to get anon', anon);
  //    getPublicModels();
  //  //this.setState({
  //  //  userId: anon.username,
  //  //  showSelector: modelId === undefined,
  //  //  selectedModelId: modelId === undefined ? '' : modelId,
  //  //});
  //  });
  //}
  }

  selectModel(modelId: string) {
    window.location.hash = 'model=' + modelId;
    this.setState({
      selectedModelId: modelId,
      showSelector: false,
    });
  }

  showSelector() {
    window.location.hash = '';
    this.setState({
      showSelector: true,
    });
  }

  getModelIdFromHash(): string | undefined {
    let hash = window.location.hash.substr(1);
    let hashParts = hash.split('&');
    for (let i = 0; i < hashParts.length; i++) {
      let keyValue = hashParts[i].split('=');
      if (keyValue.length === 2 &&
          keyValue[0] === 'model') {
        return keyValue[1];
      }
    }
    return undefined;
  }

  showLogin() {
    this.setState({
      showLogin: true,
    });
  }

  showLoginCopyFlow(modelId: string) {
    this.setState({
      showLogin: true,
      copyModelId: modelId,
    });
  }

  hideLogin(selectedModelId?: string) {
    this.readAuthorizedUser();
    console.log('Selected model when hiding login is ' + selectedModelId);
    this.setState({
      showLogin: false,
      copyModelId: '',
      selectedModelId: selectedModelId === undefined ? '' : selectedModelId,
      //showSelector: this.getModelIdFromHash() === undefined,
    });
    if (selectedModelId !== undefined) {
      this.selectModel(selectedModelId);
    }
  }

  render() {
    if (this.state.showSelector) {
      return (
        <div className='app'>
          <PageHeader
            showSelectorFn={this.showSelectorBound}
            showLoginFn={this.showLoginBound}
            showLoginCopyFlow={this.showLoginCopyFlowBound}
            selectModel={this.selectModelBound}
            modelId=''
            userId={this.state.userId}
          />
          <Login
            hideLogin={this.hideLoginBound}
            hidden={!this.state.showLogin}
            copyModelId={this.state.copyModelId}
          />
          <Hideable
            hidden={this.state.showLogin}>
            <ModelSelector
              selectModelFn={this.selectModelBound}
              ownerId={this.state.userId}
              showLoginFn={this.showLoginBound}
              prefab={false}
            />
          </Hideable>
        </div>
      );
    } else {
      let joyrideSteps = getJoyrideFromHash();
      return (
        <div className='app'>
          <PageHeader
            userId={this.state.userId}
            showSelectorFn={this.showSelectorBound}
            showLoginFn={this.showLoginBound}
            selectModel={this.selectModelBound}
            showLoginCopyFlow={this.showLoginCopyFlowBound}
            modelId={this.state.selectedModelId}
          />
          <Login
            hideLogin={this.hideLoginBound}
            hidden={!this.state.showLogin}
            copyModelId={this.state.copyModelId}
          />
          <Hideable
            hidden={this.state.selectedModelId === null}
          >
            <Model
              modelId={this.state.selectedModelId}
              userId={this.state.userId}
              showLoginFn={this.showLoginBound}
              copyModelToUser={this.state.copyModelToUser}
            />
          </Hideable>
          { joyrideSteps === null ? <div></div> :
            <Joyride
              continuous={false}
              scrollOffset={200}
              showSkipButton={true}
              steps={joyrideSteps}
            />
          }
        </div>
      );
    }
  }
}
