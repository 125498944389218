import React, { Component } from 'react';
import { createModel } from './graphql/mutations';
import API from '@aws-amplify/api';
import { Model } from './models/index.d';
import { getPrivateModels, getPublicModels } from './queries';

type ModelsTabProps = {
  text: string,
  name: string,
  selectTab: (name: string) => void,
  selectedTabName: string,
};

type ModelsTabState = {
};

class ModelsTab extends Component<ModelsTabProps, ModelsTabState> {
  selectTabBound: () => void;
  constructor(props: ModelsTabProps) {
    super(props);
    this.selectTabBound = this.selectTab.bind(this);
  }

  selectTab() {
    this.props.selectTab(this.props.name);
  }

  render() {
    return (
      <div className={
          this.props.name === this.props.selectedTabName ? "selected modelSelectorTab" : "unselected modelSelectorTab"}
          onClick={this.selectTabBound}>
        {this.props.text}
      </div>
    );
  }
}


// Main class -- model selector

type ModelSelectorProps = {
  selectModelFn: (id: string) => void,
  ownerId: string,
  showLoginFn: () => void,
  prefab: boolean,
}

type ModelSelectorState = {
  myModels: Array<Model>,
  publicModels: Array<Model>,
  selectedTabName: string,
}

function prefabFilter(prefabOnly: boolean, model: Model) : boolean {
  if (!prefabOnly) return true;
  return model.inputVariables !== undefined && model.inputVariables !== null && model.inputVariables.length > 0;
}

export class ModelSelector extends Component<ModelSelectorProps, ModelSelectorState> {
  newModelBound: () => void;
  selectTabBound: (name: string) => void;

  constructor(props: ModelSelectorProps) {
    super(props);
    this.newModelBound = this.newModel.bind(this);
    this.state = {
      myModels: [],
      publicModels: [],
      selectedTabName: "myModels",
    };
    this.selectTabBound = this.selectTab.bind(this);
  }

  componentDidMount() {
    this.getModels();
  }

  selectTab(name: string) {
    this.setState({
        selectedTabName: name
    });
  }

  async getModels() {
    let myModels: Promise<Array<Model>> = getPrivateModels(this.props.ownerId);
    let publicModels: Promise<Array<Model>> = getPublicModels();
    const myModelsFetched = (await myModels).filter(model => prefabFilter(this.props.prefab, model));
    const publicModelsFetched = (await publicModels).filter(model => prefabFilter(this.props.prefab, model));
    console.log('selector should show', publicModelsFetched);
    this.setState({
        myModels: myModelsFetched,
        publicModels: publicModelsFetched,
        selectedTabName: myModelsFetched.length === 0 && publicModelsFetched.length > 0 ?
            "publicModels" : "myModels",
    });
  }

  async newModel() {
    if (this.props.ownerId === '') {
      this.props.showLoginFn();
    }
    try {
      const created = await API.graphql({ query: createModel,
        variables: { input: {
            ownerId: this.props.ownerId,
            name: 'Untitled',
        }}}) as any;
      this.props.selectModelFn(created.data.createModel.id);
    } catch (e) {
      console.log(e);
      return;
    }
  }

  render() {
    let rows = [];
    let models = this.state.selectedTabName === 'myModels' ?
        this.state.myModels : this.state.publicModels;
    for (let i = 0; i < models.length; i++) {
      rows.push(
        <div
          key={i}
          className="modelListItem"
          onClick={this.props.selectModelFn.bind(null, models[i].id)}>
          <div>
            {models[i].name}
          </div>
          {this.state.selectedTabName === 'myModels' ? <div></div> :
            <div>
              from {models[i].ownerId}
            </div>
          }
        </div>
      );
    }
    return (
      <div className="modelSelector">
        <div
          className="header"
        >
          Select a Simulatory Model
        </div>
        <div className="modelSelectorTabs">
          <ModelsTab
              name="myModels"
              text="My Models"
              selectTab={this.selectTabBound}
              selectedTabName={this.state.selectedTabName}
          />
          <ModelsTab
              name="publicModels"
              text="Public Models"
              selectTab={this.selectTabBound}
              selectedTabName={this.state.selectedTabName}
          />
          <ModelsTab
              name="sharedModels"
              text="Models Shared with Me"
              selectTab={this.selectTabBound}
              selectedTabName={this.state.selectedTabName}
          />
        </div>
        {rows}
        <div
          className="modelListItem newModel"
          onClick={this.newModelBound}
        >
        Or, Create a New Model
        </div>
      </div>
    );
  }
}
