import { Component } from 'react';

type HideableProps = {
  hidden: boolean
};

type HideableState = {
};

export class Hideable extends Component<HideableProps, HideableState> {
  render() {
    if (this.props.hidden) {
      return null;
    }
    return (
      <div>
        {this.props.children}
      </div>
    );
  }
};
